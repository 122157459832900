import React, { useEffect, useState } from "react";

import UiIcon from "../icon/UiIcon";

import "./UiCheckList.scss";

const UiCheck = (props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (typeof props.value !== undefined) setActive(props.value);
  }, [props.value]);

  const setCheck = () => {
    let new_val = !active;
    setActive(new_val);
    props.onChange(new_val);
  };

  return (
    <button
      className="check-item"
      disabled={props.disabled}
      onClick={() => setCheck()}
    >
      <div className={"check-item-btn" + (active ? " active" : "")}>
        {active && <UiIcon fill="gray" icon="check" size={20} />}
      </div>
      <p>
        {props.label}
        <span> {props.labelMark}</span>
      </p>
    </button>
  );
};
export default UiCheck;
