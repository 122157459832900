import React from "react";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnIcon from "../../components/buttons/UiBtnIcon";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Static.scss";

function VacancyPage() {
  return (
    <PageContainer
      page="vacancies"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/vacancies", label: "Вакансии" }]}
        pageName="Вакансии"
      />
      <Container>
        <div className="contacts-wrap">
          <div className="vacancies-info-list">
            <h3>Открытые вакансии:</h3>
            <div className="vacancies-info">
              На данный момент свободных вакансий нет. <br />
              Вы можете отправить на нашу почту <br />
              <a href="">
                ___
              </a>{" "}
              <br />
              своё полное резюме (обязательно с фото и номером телефона) и в
              случае появления соответствующей свободной вакансии, мы с Вами
              свяжемся
            </div>
          </div>
          <div className="contacts-info-list">
            <h3>ПРИСОЕДИНЯЙТЕСЬ К НАМ В СОЦ. СЕТЯХ</h3>
            <div className="contacts-info">
              <UiBtnIcon
                color="black"
                icon="vk"
                iconSize="24"
                fill="white"
                href="https://vk.com"
                size="36"
                style={{ marginRight: 16 }}
                target="_blank"
              />
              <UiBtnIcon
                color="black"
                icon="instagram"
                iconSize="24"
                fill="white"
                href="https://www.instagram.com"
                size="36"
                target="_blank"
              />
            </div>
            <h3>Наши магазины</h3>
            {/*
            <div className="contacts-map">
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A878ab8d1e7833d387a7929cae042dd47d6efca736885adb307fba5bab1a34516&amp;source=constructor"
                width="100%"
                height="400"
                frameborder="0"
              ></iframe>
            </div>
            */}
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default VacancyPage;
