  
  export const getTimeZone = () => {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    var date = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
    
    return `UTC ${date == "-00:00" ? "+00:00" : date}`
  }

  export const getCurrentTime = () => {
    let current_time = new Date();
    let hh = current_time.getHours();
    let mm = current_time.getMinutes();
    if (hh < 10) hh = '0' + hh;
    if (mm < 10) mm = '0' + mm;
    return [hh, mm].join(':');
}

export const formatDateDMY = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
}

export const formatDateYMD = (date, delimetr = ".") => {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  } else {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  }

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join(delimetr);
};


export const formatDateHM = (date) => {
  var d = new Date(date),
  hours = d.getHours(),
  minutes = d.getMinutes();

  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;

  return [hours, minutes].join(':');
}

export const excludeOldTime = (_date, _times, _t = false ) => { 
  let _currentData = new Date();
  let _selectedData = new Date(_date);

  let _arr = JSON.parse(JSON.stringify(_times));

  if(_currentData.getDate() === _selectedData.getDate() ){
    let _index = _arr.length - 1; 

    let hh = _currentData.getHours() + (_t ? 1 : 2);
    if(hh >= 24) {
      _arr = [];
    } else {
      _currentData.setHours(hh);
      _arr.map((item, index) => {
        if (item) {
          let time1 = item.split(" - ")[0];
          let time2 = item.split(" - ")[1];
      
          if (
            Math.round(time1.split(":")[0]) <= _currentData.getHours() &&
            _currentData.getHours() <= Math.round(time2.split(":")[0])
          ) {
            _index = index;
          }
        }
      }); 
      _arr.splice( 0, _index + (_arr[0] == null ? 1 : 0)); 
      let c = _arr.filter((item) => {  if(item == null){
        return item;
      } });
      if( c.length == 0 ){
        _arr.unshift(null);
      }
      
    }  
  }  
  return (_arr);
};
