import React from "react";
import { useLocation } from "react-router-dom";

import UiIcon from "../icon/UiIcon";

import "./UiMenuList.scss";

const UiMenuList = (props) => {
  let location = useLocation().pathname;

  var menuItems = props.menuList.map((item, index) => {
    return (
      <li
        key={index}
        className={"nav-menu-item" + (location === item.page ? " active" : "")}
      >
        {item.icon && <UiIcon icon={item.icon} size={20} />}
        <a href={item.page}>{item.title}</a>
      </li>
    );
  });

  return (
    <ul className={"nav-menu-list" + (props.vertical ? " vertical" : "")}>
      {menuItems}
    </ul>
  );
};
export default UiMenuList;
