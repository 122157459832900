import * as React from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Provider } from "react-redux";

import AppInfoLoader from "./components/main/AppInfoLoader";
import AppModalComponent from "./components/main/AppModalComponent";

import UiBtnTopScroll from "./components/buttons/UiBtnTopScroll";
import UiBtnIcon from "./components/buttons/UiBtnIcon";
import UiIcon from "./components/icon/UiIcon";
import UiHeader from "./components/header/UiHeader";
import UiFooter from "./components/footer/UiFooter";

import { useWindowDimensions } from "./hooks/useWindowDimensions";
import AppNavigator from "./navigation/AppNavigator";

import configureStore from "./redux/store";

import "./styles/app.scss";
import UiLoaderIcon from "./components/icon/UiLoaderIcon";

function App() {
  const store = configureStore();
  const { width, height } = useWindowDimensions();
  const sliderH = height - 72;

  const [modal, setModal] = useState(false);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);

  const [headerStick, setHeaderStick] = useState(false);
  const [topBtnActive, setTopBtnActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollRef = useRef(null);

  let location = useLocation().pathname;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    executeScroll();

   // alert("Внимание ! Сайт находиться в разработке, заказы временно не обрабатываться ");
  }, [location]);

  useEffect(() => {
    if (scrollPosition > 0) {
      setTopBtnActive(true);
    } else {
      setTopBtnActive(false);
    }
  }, [scrollPosition]);

  const setMenuOpen = () => {
    let new_v = !rightPanelOpen;
    setRightPanelOpen(new_v);
  };

  const executeScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const smoothScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const menuList1 = [
    { page: "/", title: "Онлайн Витрина" },
    { page: "/catalog", title: "Букеты" },
    { page: "/flowers", title: "Цветы" },
    { page: "/gifts", title: "Подарки" },

    { page: "/delivery", title: "Доставка и оплата" },
    { page: "/contacts", title: "Контакты" },
  ];

  return (
    <Provider store={store}>
      <AppInfoLoader />
      <div className="app" id="app">
        <div className="app-scroll">
          <UiHeader menuList1={menuList1} onChangeMenu={() => setMenuOpen()} />
          <AppNavigator />
          <UiLoaderIcon />
          <UiFooter />
        </div>
        {/** <UiBtnTopScroll active={topBtnActive} onClick={() => smoothScroll()} /> **/}

        <div id="fixedElem" className="app-msgs">
          <button className="app-msgs-btn">
            <UiIcon fill="white" icon="chat" size={20} />
          </button>
          <div className="app-msgs-list">
            <UiBtnIcon
              color="whatsapp"
              href="https://wa.me/+79128176377"
              fill="white"
              icon="whatsapp"
              iconSize={24}
              round
              size={36}
            />
            <UiBtnIcon
              color="telegram"
              href="https://t.me/buketbery_surgut186"
              fill="white"
              icon="telegram"
              iconSize={24}
              round
              size={36}
            />
            <UiBtnIcon
              color="vk"
              href="https://vk.com/buketbery_surgut"
              fill="white"
              icon="vk"
              iconSize={20}
              round
              size={36}
            />
            <UiBtnIcon
              color="instagram"
              href="https://instagram.com/buketbery_surgut?igshid=MzRlODBiNWFlZA=="
              fill="white"
              icon="instagram"
              iconSize={24}
              round
              size={36}
            />
          </div>
        </div>

        {/** modals */}
        <AppModalComponent />
      </div>
    </Provider>
  );
}

export default App;
