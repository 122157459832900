import ENV from './Env.js';



export const getCategoryByTitle = (_title) => {
  return fetch(`${ENV.API_URL}/category/title/${_title}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}

export const getSubListById = (_id) => {
  return fetch(`${ENV.API_URL}/category/sub/show/${_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const getRootList = (_id) => {

  return fetch(`${ENV.API_URL}/category/root/${_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const getMenuList = () => {

  return fetch(`${ENV.API_URL}/category/home`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const getList = () => {

  return fetch(`${ENV.API_URL}/category/all`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}


export const makeCategory = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/category/store`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateCategory = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/category/update/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

