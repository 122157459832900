import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgLogo } from "../../assets/images/logo/logo.svg";

import Container from "../container/Container";
import UiBtnColorIcon from "../buttons/UiBtnColorIcon";
import UiMenuList from "../menu/UiMenuList";

import { getStoredBasket as getStoredBasketAction } from "../../redux/modules/login";

import "./UiHeader.scss";

const UiHeader = (props) => {
  let navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [basket, setBasket] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const setMenuOpen = () => {
    let new_v = !dropdownOpen;
    setDropdownOpen(new_v);
    props.onChangeMenu();
  };

  useEffect(() => {
    if (props.login.basketList) {
      setBasket(props.getStoredBasket());
    }
  }, [props.login.basketList]);

  return (
    <header className="header-wrap">
      {/* header */}
      <Container>
        <div className="header">
          <div className="header-mobile-menu">
            <UiBtnColorIcon
              color="dark"
              fill="dark"
              icon="menu"
              iconSize={20}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              outline={true}
              small={true}
              text=""
            />
          </div>

          <a className="header-logo" href="/">
            <SvgLogo className="header-logo-icon" />
          </a>

          <nav className={"nav-menu nav-menu-list-wrap" + (mobileMenuOpen ? " open" : "")}>
            <UiMenuList menuList={props.menuList1} />
          </nav>

          <nav className="nav-menu nav-menu-btns">
            <div className="nav-menu-profile">
              <UiBtnColorIcon
                color="dark"
                fill="dark"
                icon="user"
                iconSize={20}
                onClick={() => navigate("/profile")}
                outline={true}
                small={true}
                text="Профиль"
              />
              <UiBtnColorIcon
                color="brand"
                fill="white"
                icon="bag"
                iconSize={20}
                onClick={() => navigate("/order")}
                small={true}
                text="Корзина"
              />
            </div>
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default connect(({ login }) => ({ login }), {
  getStoredBasket: getStoredBasketAction,
})(UiHeader);
