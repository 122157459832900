import React from "react";

import { ReactComponent as SvgAlert } from "../../assets/icons/alert/alert.svg";
import { ReactComponent as SvgCheck } from "../../assets/icons/alert/check.svg";
import { ReactComponent as SvgClose } from "../../assets/icons/alert/close.svg";
import { ReactComponent as SvgError } from "../../assets/icons/alert/error.svg";
import { ReactComponent as SvgInfo } from "../../assets/icons/alert/info.svg";
import { ReactComponent as SvgQuestion } from "../../assets/icons/alert/question.svg";

import UiBtnColor from "../buttons/UiBtnColor";
import UiModal from "./UiModal";

import "./UiModal.scss";

const UiAlert = (props) => {
  if (props.text.length > 0) {
    var textList = props.text.map((item, index) => {
      return <p key={index}>{item}</p>;
    });
  }

  return (
    <UiModal show={props.modalOpen}>
      <div className="alert">
        <button className="modal-close-btn" onClick={props.modalClose}>
          <SvgClose className="modal-close-btn-icon" />
        </button>
        <div className="alert-body">
          {props.icon == "alert" ? (
            <SvgAlert className="alert-icon" />
          ) : props.icon == "check" ? (
            <SvgCheck className="alert-icon" />
          ) : props.icon == "error" ? (
            <SvgError className="alert-icon" />
          ) : props.icon == "info" ? (
            <SvgInfo className="alert-icon" />
          ) : props.icon == "question" ? (
            <SvgQuestion className="alert-icon" />
          ) : (
            <SvgAlert className="alert-icon" />
          )}
          {props.title ? <h3>{props.title}</h3> : null}
          {props.text ? (
            props.text.length > 0 ? (
              textList
            ) : (
              <p>{props.text}</p>
            )
          ) : null}
          <div className="alert-button">
            <UiBtnColor
              color="gray"
              text="Закрыть"
              onClick={props.modalClose}
            />
          </div>
        </div>
      </div>
    </UiModal>
  );
};
export default UiAlert;
