import React from "react";

import UiIcon from "../icon/UiIcon";

import "./UiBtnColorIcon.scss";

const UiBtnColorIcon = (props) => {
  return (
    <button
      className={
        "btn btn-color-icon" +
        (props.color ? " btn-" + props.color : "") +
        (props.disabled ? " disabled" : "") +
        (props.outline ? " outline" : "") +
        (props.small ? " small" : "") +
        (props.turnOff ? " turn-off" : "")
      }
      disabled={props.disabled}
      onClick={() => props.onClick()}
    >
      <UiIcon fill={props.fill} icon={props.icon} size={props.iconSize} />
      <span>{props.text}</span>
    </button>
  );
};
export default UiBtnColorIcon;
