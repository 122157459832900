import React from "react";

 
import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnIcon from "../../components/buttons/UiBtnIcon";
import UiIcon from "../../components/icon/UiIcon";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Static.scss";

function PolicyPage() {
  return (
    <PageContainer
      page="corp"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте - Политика конфиденциальности"
    >
      <UiPageTitle
      image={null}
        linksList={[{ href: "/corp", label: "Политика конфиденциальности" }]}
        pageName="Политика конфиденциальности"
      />
      <Container>
        <div className="corp-wrap">
          <h2>
                Политика в&nbsp;отношении обработки персональных данных
          </h2>
          <div className="corp-info">
            

            <p>1. Общие положения</p>

            <p>
              Настоящая политика обработки персональных данных составлена
              в&nbsp;соответствии с&nbsp;требованиями Федерального закона
              от&nbsp;27.07.2006. №&nbsp;152-ФЗ &laquo;О&nbsp;персональных
              данных&raquo; (далее&nbsp;&mdash; Закон о&nbsp;персональных
              данных) и&nbsp;определяет порядок обработки персональных данных
              и&nbsp;меры по&nbsp;обеспечению безопасности персональных данных,
              предпринимаемые&nbsp;ИП Горностаева Анна
              Александровна&nbsp;(далее&nbsp;&mdash; Оператор).
            </p>

            <p>
              1.1. Оператор ставит своей важнейшей целью и&nbsp;условием
              осуществления своей деятельности соблюдение прав и&nbsp;свобод
              человека и&nbsp;гражданина при обработке его персональных данных,
              в&nbsp;том числе защиты прав на&nbsp;неприкосновенность частной
              жизни, личную и&nbsp;семейную тайну.
            </p>

            <p>
              1.2. Настоящая политика Оператора в&nbsp;отношении обработки
              персональных данных (далее&nbsp;&mdash; Политика) применяется
              ко&nbsp;всей информации, которую Оператор может получить
              о&nbsp;посетителях веб-сайта&nbsp;https://www.buketbery86 | букетбери86.ru/.
            </p>

            <p>2. Основные понятия, используемые в&nbsp;Политике</p>

            <p>
              2.1. Автоматизированная обработка персональных данных&nbsp;&mdash;
              обработка персональных данных с&nbsp;помощью средств
              вычислительной техники.
            </p>

            <p>
              2.2. Блокирование персональных данных&nbsp;&mdash; временное
              прекращение обработки персональных данных (за&nbsp;исключением
              случаев, если обработка необходима для уточнения персональных
              данных).
            </p>

            <p>
              2.3. Веб-сайт&nbsp;&mdash; совокупность графических
              и&nbsp;информационных материалов, а&nbsp;также программ для ЭВМ
              и&nbsp;баз данных, обеспечивающих их&nbsp;доступность в&nbsp;сети
              интернет по&nbsp;сетевому адресу&nbsp;https://www.buketbery86 | букетбери86.ru/.
            </p>

            <p>
              2.4. Информационная система персональных данных&nbsp;&mdash;
              совокупность содержащихся в&nbsp;базах данных персональных данных
              и&nbsp;обеспечивающих их&nbsp;обработку информационных технологий
              и&nbsp;технических средств.
            </p>

            <p>
              2.5. Обезличивание персональных данных&nbsp;&mdash; действия,
              в&nbsp;результате которых невозможно определить без использования
              дополнительной информации принадлежность персональных данных
              конкретному Пользователю или иному субъекту персональных данных.
            </p>

            <p>
              2.6. Обработка персональных данных&nbsp;&mdash; любое действие
              (операция) или совокупность действий (операций), совершаемых
              с&nbsp;использованием средств автоматизации или без использования
              таких средств с&nbsp;персональными данными, включая сбор, запись,
              систематизацию, накопление, хранение, уточнение (обновление,
              изменение), извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных.
            </p>

            <p>
              2.7. Оператор&nbsp;&mdash; государственный орган, муниципальный
              орган, юридическое или физическое лицо, самостоятельно или
              совместно с&nbsp;другими лицами организующие
              и/или&nbsp;осуществляющие обработку персональных данных,
              а&nbsp;также определяющие цели обработки персональных данных,
              состав персональных данных, подлежащих обработке, действия
              (операции), совершаемые с&nbsp;персональными данными.
            </p>

            <p>
              2.8. Персональные данные&nbsp;&mdash; любая информация,
              относящаяся прямо или косвенно к&nbsp;определенному или
              определяемому Пользователю
              веб-сайта&nbsp;https://www.buketbery86 | букетбери86.ru/.
            </p>

            <p>
              2.9. Персональные данные, разрешенные субъектом персональных
              данных для распространения,&nbsp;&mdash; персональные данные,
              доступ неограниченного круга лиц к&nbsp;которым предоставлен
              субъектом персональных данных путем дачи согласия
              на&nbsp;обработку персональных данных, разрешенных субъектом
              персональных данных для распространения в&nbsp;порядке,
              предусмотренном Законом о&nbsp;персональных данных
              (далее&nbsp;&mdash; персональные данные, разрешенные для
              распространения).
            </p>

            <p>
              2.10. Пользователь&nbsp;&mdash; любой посетитель
              веб-сайта&nbsp;https://www.buketbery86 | букетбери86.ru/.
            </p>

            <p>
              2.11. Предоставление персональных данных&nbsp;&mdash; действия,
              направленные на&nbsp;раскрытие персональных данных определенному
              лицу или определенному кругу лиц.
            </p>

            <p>
              2.12. Распространение персональных данных&nbsp;&mdash; любые
              действия, направленные на&nbsp;раскрытие персональных данных
              неопределенному кругу лиц (передача персональных данных) или
              на&nbsp;ознакомление с&nbsp;персональными данными неограниченного
              круга лиц, в&nbsp;том числе обнародование персональных данных
              в&nbsp;средствах массовой информации, размещение
              в&nbsp;информационно-телекоммуникационных сетях или предоставление
              доступа к&nbsp;персональным данным каким-либо иным способом.
            </p>

            <p>
              2.13. Трансграничная передача персональных данных&nbsp;&mdash;
              передача персональных данных на&nbsp;территорию иностранного
              государства органу власти иностранного государства, иностранному
              физическому или иностранному юридическому лицу.
            </p>

            <p>
              2.14. Уничтожение персональных данных&nbsp;&mdash; любые действия,
              в&nbsp;результате которых персональные данные уничтожаются
              безвозвратно с&nbsp;невозможностью дальнейшего восстановления
              содержания персональных данных в&nbsp;информационной системе
              персональных данных и/или&nbsp;уничтожаются материальные носители
              персональных данных.
            </p>

            <p>3. Основные права и&nbsp;обязанности Оператора</p>

            <p>3.1. Оператор имеет право:</p>

            <p>
              &mdash;&nbsp;получать от&nbsp;субъекта персональных данных
              достоверные информацию и/или&nbsp;документы, содержащие
              персональные данные;
            </p>

            <p>
              &mdash;&nbsp;в&nbsp;случае отзыва субъектом персональных данных
              согласия на&nbsp;обработку персональных данных, а&nbsp;также,
              направления обращения с&nbsp;требованием о&nbsp;прекращении
              обработки персональных данных, Оператор вправе продолжить
              обработку персональных данных без согласия субъекта персональных
              данных при наличии оснований, указанных в&nbsp;Законе
              о&nbsp;персональных данных;
            </p>

            <p>
              &mdash;&nbsp;самостоятельно определять состав и&nbsp;перечень мер,
              необходимых и&nbsp;достаточных для обеспечения выполнения
              обязанностей, предусмотренных Законом о&nbsp;персональных данных
              и&nbsp;принятыми в&nbsp;соответствии с&nbsp;ним нормативными
              правовыми актами, если иное не&nbsp;предусмотрено Законом
              о&nbsp;персональных данных или другими федеральными законами.
            </p>

            <p>3.2. Оператор обязан:</p>

            <p>
              &mdash;&nbsp;предоставлять субъекту персональных данных
              по&nbsp;его просьбе информацию, касающуюся обработки его
              персональных данных;
            </p>

            <p>
              &mdash;&nbsp;организовывать обработку персональных данных
              в&nbsp;порядке, установленном действующим
              законодательством&nbsp;РФ;
            </p>

            <p>
              &mdash;&nbsp;отвечать на&nbsp;обращения и&nbsp;запросы субъектов
              персональных данных и&nbsp;их&nbsp;законных представителей
              в&nbsp;соответствии с&nbsp;требованиями Закона о&nbsp;персональных
              данных;
            </p>

            <p>
              &mdash;&nbsp;сообщать в&nbsp;уполномоченный орган по&nbsp;защите
              прав субъектов персональных данных по&nbsp;запросу этого органа
              необходимую информацию в&nbsp;течение 10&nbsp;дней с&nbsp;даты
              получения такого запроса;
            </p>

            <p>
              &mdash;&nbsp;публиковать или иным образом обеспечивать
              неограниченный доступ к&nbsp;настоящей Политике в&nbsp;отношении
              обработки персональных данных;
            </p>

            <p>
              &mdash;&nbsp;принимать правовые, организационные
              и&nbsp;технические меры для защиты персональных данных
              от&nbsp;неправомерного или случайного доступа к&nbsp;ним,
              уничтожения, изменения, блокирования, копирования, предоставления,
              распространения персональных данных, а&nbsp;также от&nbsp;иных
              неправомерных действий в&nbsp;отношении персональных данных;
            </p>

            <p>
              &mdash;&nbsp;прекратить передачу (распространение, предоставление,
              доступ) персональных данных, прекратить обработку
              и&nbsp;уничтожить персональные данные в&nbsp;порядке
              и&nbsp;случаях, предусмотренных Законом о&nbsp;персональных
              данных;
            </p>

            <p>
              &mdash;&nbsp;исполнять иные обязанности, предусмотренные Законом
              о&nbsp;персональных данных.
            </p>

            <p>
              4. Основные права и&nbsp;обязанности субъектов персональных данных
            </p>

            <p>4.1. Субъекты персональных данных имеют право:</p>

            <p>
              &mdash;&nbsp;получать информацию, касающуюся обработки его
              персональных данных, за&nbsp;исключением случаев, предусмотренных
              федеральными законами. Сведения предоставляются субъекту
              персональных данных Оператором в&nbsp;доступной форме,
              и&nbsp;в&nbsp;них не&nbsp;должны содержаться персональные данные,
              относящиеся к&nbsp;другим субъектам персональных данных,
              за&nbsp;исключением случаев, когда имеются законные основания для
              раскрытия таких персональных данных. Перечень информации
              и&nbsp;порядок ее&nbsp;получения установлен Законом
              о&nbsp;персональных данных;
            </p>

            <p>
              &mdash;&nbsp;требовать от&nbsp;оператора уточнения его
              персональных данных, их&nbsp;блокирования или уничтожения
              в&nbsp;случае, если персональные данные являются неполными,
              устаревшими, неточными, незаконно полученными или не&nbsp;являются
              необходимыми для заявленной цели обработки, а&nbsp;также принимать
              предусмотренные законом меры по&nbsp;защите своих прав;
            </p>

            <p>
              &mdash;&nbsp;выдвигать условие предварительного согласия при
              обработке персональных данных в&nbsp;целях продвижения
              на&nbsp;рынке товаров, работ и&nbsp;услуг;
            </p>

            <p>
              &mdash;&nbsp;на&nbsp;отзыв согласия на&nbsp;обработку персональных
              данных, а&nbsp;также, на&nbsp;направление требования
              о&nbsp;прекращении обработки персональных данных;
            </p>

            <p>
              &mdash;&nbsp;обжаловать в&nbsp;уполномоченный орган по&nbsp;защите
              прав субъектов персональных данных или в&nbsp;судебном порядке
              неправомерные действия или бездействие Оператора при обработке его
              персональных данных;
            </p>

            <p>
              &mdash;&nbsp;на&nbsp;осуществление иных прав, предусмотренных
              законодательством&nbsp;РФ.
            </p>

            <p>4.2. Субъекты персональных данных обязаны:</p>

            <p>
              &mdash;&nbsp;предоставлять Оператору достоверные данные
              о&nbsp;себе;
            </p>

            <p>
              &mdash;&nbsp;сообщать Оператору об&nbsp;уточнении (обновлении,
              изменении) своих персональных данных.
            </p>

            <p>
              4.3. Лица, передавшие Оператору недостоверные сведения
              о&nbsp;себе, либо сведения о&nbsp;другом субъекте персональных
              данных без согласия последнего, несут ответственность
              в&nbsp;соответствии с&nbsp;законодательством&nbsp;РФ.
            </p>

            <p>5. Принципы обработки персональных данных</p>

            <p>
              5.1. Обработка персональных данных осуществляется на&nbsp;законной
              и&nbsp;справедливой основе.
            </p>

            <p>
              5.2. Обработка персональных данных ограничивается достижением
              конкретных, заранее определенных и&nbsp;законных целей.
              Не&nbsp;допускается обработка персональных данных, несовместимая
              с&nbsp;целями сбора персональных данных.
            </p>

            <p>
              5.3. Не&nbsp;допускается объединение баз данных, содержащих
              персональные данные, обработка которых осуществляется
              в&nbsp;целях, несовместимых между собой.
            </p>

            <p>
              5.4. Обработке подлежат только персональные данные, которые
              отвечают целям их&nbsp;обработки.
            </p>

            <p>
              5.5. Содержание и&nbsp;объем обрабатываемых персональных данных
              соответствуют заявленным целям обработки. Не&nbsp;допускается
              избыточность обрабатываемых персональных данных по&nbsp;отношению
              к&nbsp;заявленным целям их&nbsp;обработки.
            </p>

            <p>
              5.6. При обработке персональных данных обеспечивается точность
              персональных данных, их&nbsp;достаточность,
              а&nbsp;в&nbsp;необходимых случаях и&nbsp;актуальность
              по&nbsp;отношению к&nbsp;целям обработки персональных данных.
              Оператор принимает необходимые меры и/или&nbsp;обеспечивает
              их&nbsp;принятие по&nbsp;удалению или уточнению неполных или
              неточных данных.
            </p>

            <p>
              5.7. Хранение персональных данных осуществляется в&nbsp;форме,
              позволяющей определить субъекта персональных данных,
              не&nbsp;дольше, чем этого требуют цели обработки персональных
              данных, если срок хранения персональных данных не&nbsp;установлен
              федеральным законом, договором, стороной которого,
              выгодоприобретателем или поручителем по&nbsp;которому является
              субъект персональных данных. Обрабатываемые персональные данные
              уничтожаются либо обезличиваются по&nbsp;достижении целей
              обработки или в&nbsp;случае утраты необходимости в&nbsp;достижении
              этих целей, если иное не&nbsp;предусмотрено федеральным законом.
            </p>

            <p>6. Цели обработки персональных данных</p>

            <table>
              <tbody>
                <tr>
                  <th>Цель обработки</th>
                  <td>
                    информирование Пользователя посредством отправки электронных
                    писем
                  </td>
                </tr>
                <tr>
                  <th>Персональные данные</th>
                  <td>
                    <ul>
                      <li>номера телефонов</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Правовые основания</th>
                  <td>
                    <ul>
                      <li>
                        Федеральный закон &laquo;Об&nbsp;информации,
                        информационных технологиях и&nbsp;о&nbsp;защите
                        информации&raquo; от&nbsp;27.07.2006&nbsp;N 149-ФЗ
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Виды обработки персональных данных</th>
                  <td>
                    <ul>
                      <li>
                        Сбор, запись, систематизация, накопление, хранение,
                        уничтожение и&nbsp;обезличивание персональных данных
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>7. Условия обработки персональных данных</p>

            <p>
              7.1. Обработка персональных данных осуществляется с&nbsp;согласия
              субъекта персональных данных на&nbsp;обработку его персональных
              данных.
            </p>

            <p>
              7.2. Обработка персональных данных необходима для достижения
              целей, предусмотренных международным договором Российской
              Федерации или законом, для осуществления возложенных
              законодательством Российской Федерации на&nbsp;оператора функций,
              полномочий и&nbsp;обязанностей.
            </p>

            <p>
              7.3. Обработка персональных данных необходима для осуществления
              правосудия, исполнения судебного акта, акта другого органа или
              должностного лица, подлежащих исполнению в&nbsp;соответствии
              с&nbsp;законодательством Российской Федерации
              об&nbsp;исполнительном производстве.
            </p>

            <p>
              7.4. Обработка персональных данных необходима для исполнения
              договора, стороной которого либо выгодоприобретателем или
              поручителем по&nbsp;которому является субъект персональных данных,
              а&nbsp;также для заключения договора по&nbsp;инициативе субъекта
              персональных данных или договора, по&nbsp;которому субъект
              персональных данных будет являться выгодоприобретателем или
              поручителем.
            </p>

            <p>
              7.5. Обработка персональных данных необходима для осуществления
              прав и&nbsp;законных интересов оператора или третьих лиц либо для
              достижения общественно значимых целей при условии, что при этом
              не&nbsp;нарушаются права и&nbsp;свободы субъекта персональных
              данных.
            </p>

            <p>
              7.6. Осуществляется обработка персональных данных, доступ
              неограниченного круга лиц к&nbsp;которым предоставлен субъектом
              персональных данных либо по&nbsp;его просьбе (далее&nbsp;&mdash;
              общедоступные персональные данные).
            </p>

            <p>
              7.7. Осуществляется обработка персональных данных, подлежащих
              опубликованию или обязательному раскрытию в&nbsp;соответствии
              с&nbsp;федеральным законом.
            </p>

            <p>
              8. Порядок сбора, хранения, передачи и&nbsp;других видов обработки
              персональных данных
            </p>

            <p>
              Безопасность персональных данных, которые обрабатываются
              Оператором, обеспечивается путем реализации правовых,
              организационных и&nbsp;технических мер, необходимых для выполнения
              в&nbsp;полном объеме требований действующего законодательства
              в&nbsp;области защиты персональных данных.
            </p>

            <p>
              8.1. Оператор обеспечивает сохранность персональных данных
              и&nbsp;принимает все возможные меры, исключающие доступ
              к&nbsp;персональным данным неуполномоченных лиц.
            </p>

            <p>
              8.2. Персональные данные Пользователя никогда, ни&nbsp;при каких
              условиях не&nbsp;будут переданы третьим лицам, за&nbsp;исключением
              случаев, связанных с&nbsp;исполнением действующего
              законодательства либо в&nbsp;случае, если субъектом персональных
              данных дано согласие Оператору на&nbsp;передачу данных третьему
              лицу для исполнения обязательств по&nbsp;гражданско-правовому
              договору.
            </p>

            <p>
              8.3. В&nbsp;случае выявления неточностей в&nbsp;персональных
              данных, Пользователь может актуализировать их&nbsp;самостоятельно,
              путем направления Оператору уведомление на&nbsp;адрес электронной
              почты Оператора&nbsp;buketbery@yandex.ru&nbsp;с&nbsp;пометкой
              &laquo;Актуализация персональных данных&raquo;.
            </p>

            <p>
              8.4. Срок обработки персональных данных определяется достижением
              целей, для которых были собраны персональные данные, если иной
              срок не&nbsp;предусмотрен договором или действующим
              законодательством.
              <br />
              Пользователь может в&nbsp;любой момент отозвать свое согласие
              на&nbsp;обработку персональных данных, направив Оператору
              уведомление посредством электронной почты на&nbsp;электронный
              адрес Оператора&nbsp;buketbery@yandex.ru&nbsp;с&nbsp;пометкой
              &laquo;Отзыв согласия на&nbsp;обработку персональных
              данных&raquo;.
            </p>

            <p>
              8.5. Вся информация, которая собирается сторонними сервисами,
              в&nbsp;том числе платежными системами, средствами связи
              и&nbsp;другими поставщиками услуг, хранится и&nbsp;обрабатывается
              указанными лицами (Операторами) в&nbsp;соответствии
              с&nbsp;их&nbsp;Пользовательским соглашением и&nbsp;Политикой
              конфиденциальности. Субъект персональных данных
              и/или&nbsp;с&nbsp;указанными документами. Оператор не&nbsp;несет
              ответственность за&nbsp;действия третьих лиц, в&nbsp;том числе
              указанных в&nbsp;настоящем пункте поставщиков услуг.
            </p>

            <p>
              8.6. Установленные субъектом персональных данных запреты
              на&nbsp;передачу (кроме предоставления доступа), а&nbsp;также
              на&nbsp;обработку или условия обработки (кроме получения доступа)
              персональных данных, разрешенных для распространения,
              не&nbsp;действуют в&nbsp;случаях обработки персональных данных
              в&nbsp;государственных, общественных и&nbsp;иных публичных
              интересах, определенных законодательством&nbsp;РФ.
            </p>

            <p>
              8.7. Оператор при обработке персональных данных обеспечивает
              конфиденциальность персональных данных.
            </p>

            <p>
              8.8. Оператор осуществляет хранение персональных данных
              в&nbsp;форме, позволяющей определить субъекта персональных данных,
              не&nbsp;дольше, чем этого требуют цели обработки персональных
              данных, если срок хранения персональных данных не&nbsp;установлен
              федеральным законом, договором, стороной которого,
              выгодоприобретателем или поручителем по&nbsp;которому является
              субъект персональных данных.
            </p>

            <p>
              8.9. Условием прекращения обработки персональных данных может
              являться достижение целей обработки персональных данных, истечение
              срока действия согласия субъекта персональных данных, отзыв
              согласия субъектом персональных данных или требование
              о&nbsp;прекращении обработки персональных данных, а&nbsp;также
              выявление неправомерной обработки персональных данных.
            </p>

            <p>
              9. Перечень действий, производимых Оператором с&nbsp;полученными
              персональными данными
            </p>

            <p>
              9.1. Оператор осуществляет сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление
              и&nbsp;уничтожение персональных данных.
            </p>

            <p>
              9.2. Оператор осуществляет автоматизированную обработку
              персональных данных с&nbsp;получением и/или&nbsp;передачей
              полученной информации по&nbsp;информационно-телекоммуникационным
              сетям или без таковой.
            </p>

            <p>10. Трансграничная передача персональных данных</p>

            <p>
              10.1. Оператор до&nbsp;начала осуществления деятельности
              по&nbsp;трансграничной передаче персональных данных обязан
              уведомить уполномоченный орган по&nbsp;защите прав субъектов
              персональных данных о&nbsp;своем намерении осуществлять
              трансграничную передачу персональных данных (такое уведомление
              направляется отдельно от&nbsp;уведомления о&nbsp;намерении
              осуществлять обработку персональных данных).
            </p>

            <p>
              10.2. Оператор до&nbsp;подачи вышеуказанного уведомления, обязан
              получить от&nbsp;органов власти иностранного государства,
              иностранных физических лиц, иностранных юридических лиц, которым
              планируется трансграничная передача персональных данных,
              соответствующие сведения.
            </p>

            <p>11. Конфиденциальность персональных данных</p>

            <p>
              Оператор и&nbsp;иные лица, получившие доступ к&nbsp;персональным
              данным, обязаны не&nbsp;раскрывать третьим лицам
              и&nbsp;не&nbsp;распространять персональные данные без согласия
              субъекта персональных данных, если иное не&nbsp;предусмотрено
              федеральным законом.
            </p>

            <p>12. Заключительные положения</p>

            <p>
              12.1. Пользователь может получить любые разъяснения
              по&nbsp;интересующим вопросам, касающимся обработки его
              персональных данных, обратившись к&nbsp;Оператору с&nbsp;помощью
              электронной почты&nbsp;buketbery@yandex.ru.
            </p>

            <p>
              12.2. В&nbsp;данном документе будут отражены любые изменения
              политики обработки персональных данных Оператором. Политика
              действует бессрочно до&nbsp;замены ее&nbsp;новой версией.
            </p>

            <p>
              12.3. Актуальная версия Политики в&nbsp;свободном доступе
              расположена в&nbsp;сети Интернет
              по&nbsp;адресу&nbsp;http://buketbery86 | букетбери86.ru/policy.
            </p>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default PolicyPage;
