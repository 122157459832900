import OrderConstant from "../constants/OrderConstant";
import { safelyParseJSON } from "../services/Storage";

export const getInfo = (item) => {
  const volumeArray = OrderConstant.VOLUME_ARRAY;
  const trashArray = OrderConstant.TRASH_ARRAY;
  const takArray = OrderConstant.TAK_ARRAY;
  const unbuildArray = OrderConstant.UNBUILD_ARRAY;
  const packArray = OrderConstant.PACK_ARRAY;
  const liftTypes = OrderConstant.LIFT_TYPES;

  const prr_types = OrderConstant.PRR_TYPE;
  let arr = [];
  arr.push({ text: volumeArray[item.volume_car].text });
  arr.push({ text: `${item.movers} грузчика` });

  arr.push({ text: prr_types[item.prr_types].text });
  if (item.moving_types != 4) {
    if (item.is_hard_work) arr.push({ text: takArray[item.tak_value].text });
    if (item.is_assembly_disassembly)
      arr.push({ text: unbuildArray[item.unbuild_value].text });
    if (item.additional_work)
      arr.push({ text: packArray[item.pack_value].text });
  } else {
    arr.push({ text: trashArray[item.trash_value].text });
  }

  return arr;
};

export const getProductPrice = (_item, _count, _allCompositionList = []) => {
  let sum = 0;

  let priceArr = [];
  let price = _item.price;
  if (_item.composition) {
    let selectedStorage = safelyParseJSON(_item.composition);
    if (selectedStorage.length == 1) {
      let _selectedItem = null;
      if (_allCompositionList.length > 0) {
        _allCompositionList.map((_itemCL) => {
          if (_itemCL.custom_id == selectedStorage[0].custom_id)
            _selectedItem = _itemCL;
        });
      }
      if (_selectedItem) {
        priceArr = safelyParseJSON(_selectedItem.custom_sale_price);
        price = Math.round(_selectedItem.custom_price);
        if (priceArr.length > 0) {
          priceArr.map((item) => {
            if (item.count_lower <= _count && item.count_upper >= _count) {
              if (item.custom_price.includes("%")) {
                let pr = item.custom_price.replace("%", "");
                pr =
                  (_selectedItem.custom_price -
                    (pr / 100) * _selectedItem.custom_price) *
                  _count;
                price = Math.round(pr) / _count;
              } else {
                price = Math.round(item.custom_price);
              }
            }
          });
        } 
        return Math.round(price * _count);
      }
    }
  }

  if (_item.sale == 0) {
    sum = sum + _item.price * _count;
  } else {
    let _c = _count;
    sum = sum + (_c >= _item.sale_count ? _item.sale : _item.price) * _c;
  }
  return sum;
};

export const getProductSale = (_item, _allCompositionList = []) => {
  if (_item) {
    let priceArr = [];
    if (_item.composition) {
      let selectedStorage = safelyParseJSON(_item.composition);
      if (selectedStorage.length == 1) {
        let _selectedItem = null;
        if (_allCompositionList.length > 0) {
          _allCompositionList.map((_itemCL) => {
            if (_itemCL.custom_id == selectedStorage[0].custom_id)
              _selectedItem = _itemCL;
          });
        }
        if (_selectedItem) {
          priceArr = safelyParseJSON(_selectedItem.custom_sale_price);
          return priceArr;
        }
      }
    }
  }

  return [];
};

export const getPrice = (_item, _allCompositionList = []) => {
  let sum = 0;

  let priceArr = [];
  let price = _item.price;
  if (_item.composition) {
    console.log(_item);
    let selectedStorage = safelyParseJSON(_item.composition);
    if (selectedStorage.length == 1) {
      let _selectedItem = null;
      if (_allCompositionList.length > 0) {
        _allCompositionList.map((_itemCL) => {
          if (_itemCL.custom_id == selectedStorage[0].custom_id)
            _selectedItem = _itemCL;
        });
      }
      if (_selectedItem) {
        priceArr = safelyParseJSON(_selectedItem.custom_sale_price);
        price = _selectedItem.custom_price;
        if (priceArr.length > 0) {
          priceArr.map((item) => {
            if (
              item.count_lower <= _item.count &&
              item.count_upper >= _item.count
            ) {
              if (item.custom_price.includes("%")) {
                let pr = item.custom_price.replace("%", "");
                pr =
                  (_selectedItem.custom_price -
                    (pr / 100) * _selectedItem.custom_price) *
                  _item.count;
                price = Math.round(pr) / _item.count;
              } else {
                price = item.custom_price;
              }
            }
          });
        }

        return Math.round(price * _item.count);
      }
    }
  }

  if (_item.sale == 0) {
    sum = sum + _item.price * (_item.count ? _item.count : 1);
  } else {
    let _c = _item.count ? _item.count : 1;
    sum = sum + (_c >= _item.sale_count ? _item.sale : _item.price) * _c;
  }
  return sum;
};

export const getTotalPrice = (
  _arr,
  _delivery,
  _discount,
  _onlyArr = false,
  _allCompositionList = []
) => {
  let sum = 0;
  _arr.map((item, index) => {
    sum = sum + getPrice(item, _allCompositionList);
  });

  if (!_onlyArr) {
    if (_delivery) sum = sum + parseFloat(_delivery);
    sum = sum - sum * parseFloat(_discount / 100);
  }
  //console.log( _arr, _delivery, _discount , sum);

  return Math.round(sum);
};
