export default {
  STORE_KEY: 'a56z0fzrNpl^2',
  //Dev

  //Prod
  HOST_URL: 'https://buketbery86.ru/',
  API_URL: 'https://buketbery86.ru/server/public/api/v1',
  PUBLIC_URL: 'https://buketbery86.ru/server/public/uploads/',
  //STORAGE_URL: '',
 
  getHeaders: function () {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
  },

  getHeadersWT: function (login,password) { 
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic '+btoa(unescape(encodeURIComponent( login+':'+password )))
    }
  }
}; 