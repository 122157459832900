import React from "react";
import UiIcon from "../icon/UiIcon";

import "./UiBtnColor.scss";

const UiBtnColor = (props) => {
  return (
    <button
      className={
        "btn btn-color" +
        (props.arrow ? " arrow" : "") + 
        (props.color ? " btn-" + props.color : "") +
        (props.disabled ? " disabled" : "") +
        (props.outline ? " outline" : "") +
        (props.small ? " small" : "")
      }
      disabled={props.disabled}
      onClick={() => props.onClick()}
    >
      <span>{props.text}</span>
      {props.arrow && (
        <UiIcon
          className="btn-color-arrow"
          fill="dark"
          icon="arrow-right"
          size={48}
        />
      )}
    </button>
  );
};
export default UiBtnColor;
