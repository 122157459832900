import React from "react";

import "./UiLoaderIcon.scss";

const UiLoaderIcon = (props) => {
  return (
    props.type === "dual-ball" && (
      <div class="loading-dual-ball">
        <div class="dual-ball">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
};
export default UiLoaderIcon;
