import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import {
  getStorageListByIdArray as getStorageListByIdArrayAction,
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
  getStoredUserBasket as getStoredUserBasketAction,
  changeUserBasket as changeUserBasketAction,
} from "../../redux/modules/login";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  getSettingDelivery as getSettingDeliveryAction,
} from "../../redux/modules/setting";
import {
  updateOrder as updateOrderAction,
  getOrderId as getOrderIdAction,
  makePayment as makePaymentAction,
} from "../../redux/modules/order";
 
import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
 
 
import UiPageTitle from "../../components/header/UiPageTitle";

import UiLoader from "../../components/modals/UiLoader";

import "./Order.scss";
import UiSelectOption from "../../components/forms/UiSelectOption";
import Env from "../../services/Env";

function UserPayPage(props) {
  let { id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  const [order, setOrder] = useState(null);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    props.getOrderId(id);
  }, [location]);

  useEffect(() => {
    console.log("props.order.order", props.order.order)
    if (props.order.order) {
      let _order = props.order.order;
      if (_order.id) {
        setOrder(_order);
        props.makePayment(_order   ).then((res) => {
          setLoading(false); 
          if (res.confirmation) {
            window.location.href = res.confirmation.confirmation_url;
          } else {
            setText(`${res.errorCode} ${`\r\n`} ${res.errorMessage}`);
          }
        });
      }
    }
  }, [props.order.order]);

  return (
    <PageContainer
      page="order"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
      alertOpen={true}
      alertTitle="Заголовок"
      alertText="Текст сообщения"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/order", label: "Корзина" }]}
        pageName="Оплата заказа"
      />
      <Container>
        <div className="order-wrap">
          <div className="order-info">
            <h3>Оплата заказ №{id}</h3>
            <h3>{text}</h3>
          </div>
        </div>

        <UiLoader modalOpen={loading} />
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, setting, order }) => ({ storage, login, setting, order }),
  {
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,

    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    getStoredUserBasket: getStoredUserBasketAction,
    changeUserBasket: changeUserBasketAction,
    getSettingList: getSettingListAction,
    getSettingValue: getSettingValueAction,
    getSettingDelivery: getSettingDeliveryAction,

    getStorageListByIdArray: getStorageListByIdArrayAction,
    getOrderId: getOrderIdAction,
    updateOrder: updateOrderAction,

    makePayment: makePaymentAction,
  }
)(UserPayPage);
