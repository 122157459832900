import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SmartCaptcha } from "@yandex/smart-captcha";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextInputMasked from "../../components/forms/UiTextInputMasked";

import UiPageTitle from "../../components/header/UiPageTitle";

import "./Login.scss";
import UiCheck from "../../components/forms/UiCheck";

import {
  loginUser as loginUserAction,
  registerUser as registerUserAction,
  changeLoaderState as changeLoaderStateAction,
} from "../../redux/modules/login";

import { storeData, retrieveData } from "../../services/Storage";
import Env from "../../services/Env";

function LoginPage(props) {
  let navigate = useNavigate();

  const [token, setToken] = useState("");

  const [phone, setPhone] = useState(null);
  const [ePhone, setEPhone] = useState(null);
  const [code, setCode] = useState(null);
  const [isSend, setIsSend] = useState(false);
  const [agree, setAgree] = useState(false);

  const [captcha, setCaptcha] = useState(null);
  const [capCode, setCapCode] = useState(null);

  const [disable, setDisable] = useState(false);
  const [time, setTime] = useState(120);

  useEffect(() => {
    if (props.login.user) {
      navigate(`/order`);
    }
  }, [props.login.user]);

  function sendCode() {
    if (phone && token.length > 0) {
      props.changeLoaderState(true);

      var _phone = phone.replace(/ /g, "");
      _phone = _phone.replace(/\+7/g, "7");
      _phone = _phone.replace(/\(/g, "");
      _phone = _phone.replace(/\)/g, "");
      _phone = _phone.replace(/-/g, "");

      props.registerUser(_phone, capCode, token ).then((val) => {
        if (val.captcha_url) {
          setCaptcha(val.captcha_url);
        } else {
          setCaptcha(null);
        }
        props.changeLoaderState(false);
        setIsSend(true);
        setEPhone(_phone);
      });
    }
  }

  function login() {
    props.changeLoaderState(true);
    props.loginUser(ePhone, code).then((val) => {
      props.changeLoaderState(false);

      if (val) {
        if (retrieveData("from_cart")) {
          storeData("from_cart", null);
          navigate(`/order`);
        } else navigate(`/profile`);
      }
    });
  }

  return (
    <PageContainer
      page="profile"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/login", label: "Авторизация" }]}
        pageName="Войти в мой профиль"
      />
      <Container>
        <div className="login-wrap">
          <div className="login-info">
            <UiTextInputMasked
              label="Мой телефон"
              placeholder="+7 (000) 000 00-00"
              onChange={(v) => setPhone(v)}
              disabled={isSend}
            />

            <SmartCaptcha
              sitekey="ysc1_jeammfzeCsnJ8A7SiwD5ogGwMlYvDWtpXoUooCsZ2d92d10f"
              onSuccess={setToken}
            />

            {captcha && (
              <div>
                <img src={Env.PUBLIC_URL + captcha} />
                <UiTextInput
                  label="код с картинки"
                  placeholder="1234"
                  type="number"
                  onChange={(v) => setCapCode(v)}
                />
              </div>
            )}

            {isSend && !captcha ? (
              <UiTextInput
                label="СМС код"
                placeholder="1234"
                type="number"
                onChange={(v) => setCode(v)}
              />
            ) : null}

            {!isSend ? (
              <UiCheck
                label="Даю согласие на обработку и хранение персональных данных"
                onChange={() => {
                  setAgree(!agree);
                }}
              />
            ) : null}
            <p>
              После нажатия кнопки "получить код" вам придет смс на указанный
              номер
            </p>

            {token.length > 0 && (
              <>
                {captcha ? (
                  <UiBtnColor
                    color={capCode != null ? "accent" : "dark"}
                    text={"Отправить код"}
                    onClick={() => sendCode()}
                    disabled={capCode == null}
                  />
                ) : !isSend ? (
                  <UiBtnColor
                    color={agree && !disable ? "accent" : "dark"}
                    text={
                      disable
                        ? `Получить код через ${time} сек`
                        : "Получить код"
                    }
                    onClick={() => sendCode()}
                    disabled={!agree}
                  />
                ) : (
                  <UiBtnColor
                    color="accent"
                    text="Войти"
                    onClick={() => login()}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default connect(({ login }) => ({ login }), {
  loginUser: loginUserAction,
  registerUser: registerUserAction,
  changeLoaderState: changeLoaderStateAction,
})(LoginPage);
