import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBasketCard from "../../components/cards/UiBasketCard";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheckList from "../../components/forms/UiCheckList";
import UiCheck from "../../components/forms/UiCheck";
import UiGiftCard from "../../components/cards/UiGiftCard";
import UiIcon from "../../components/icon/UiIcon";
import UiLoader from "../../components/modals/UiLoader";
import UiNotify from "../../components/modals/UiNotify";
import UiPageTitle from "../../components/header/UiPageTitle";
import UiSelectDate from "../../components/forms/UiSelectDate";
import UiSelectOption from "../../components/forms/UiSelectOption";
import UiTextArea from "../../components/forms/UiTextArea";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextInputMasked from "../../components/forms/UiTextInputMasked";
import UiTextInputSV from "../../components/forms/UiTextInputSV";

import OrderConstant from "../../constants/OrderConstant";

import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
  getStoredUser as getStoredUserACtion,
  getStoredUserBasket as getStoredUserBasketAction,
  getStoredBasket as getStoredBasketAction,
  clearUserBasket as clearUserBasketAction,
  changeUserBasket as changeUserBasketAction,
  getUserPurchases as getUserPurchasesAction,
  getYandexMetricsBasket as getYandexMetricsBasketAction,
} from "../../redux/modules/login";
import { addOrder as addOrderAction } from "../../redux/modules/order";
import setting, {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  getSettingDelivery as getSettingDeliveryAction,
  getIntervalList as getIntervalListAction,
} from "../../redux/modules/setting";
import {
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
  getCompositions as getCompositionsAction,
} from "../../redux/modules/storage";

import { convertToList } from "../../utilities/storage";
import { getPrice, getTotalPrice } from "../../utilities/order";
import { toOptionsList, toArrayList } from "../../utilities/array";
import { formatDateYMD, excludeOldTime } from "../../utilities/date";
import showNotifyMsg from "../../components/notify/UiNotify";

import "./Order.scss";

function OrderPage(props) {
  let navigate = useNavigate();

  const minDeliveryPrice = 350;

  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState([]);
  const [basketList, setBasket] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [compositionList, setCompositionList] = useState([]);

  const [specialDiscount, setSpecialDiscount] = useState(false);

  const [discount, setDiscount] = useState(0);
  const [deliveryPrice, setDP] = useState(minDeliveryPrice);
  const [minimalDeliveryPrice, setMinimalDeliveryPrice] =
    useState(minDeliveryPrice);
  const [freeDeliveryPrice, setFDP] = useState(3500);
  const [deliveryInterval, setDeliveryInterval] = useState(
    excludeOldTime(new Date(), OrderConstant.ORDER_TIME_TYPE)
  );

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneRecipient, setPhoneRecipient] = useState("");

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [addressHome, setAddressHome] = useState("");
  const [addressApart, setAddressApart] = useState("");
  const [addressFloor, setAddressFloor] = useState("");

  const [addressPod, setAddressPod] = useState("");
  const [addressHCode, setAddressHCode] = useState("");

  const [comment, setComment] = useState("");
  const [commentFl, setCommentFl] = useState("");
  const [commentCurr, setCommentCurr] = useState("");

  const [distance, setDistance] = useState(null);
  const [paymentType, setPaymentType] = useState(0);
  const [movingType, setMovingType] = useState(0);
  const [selfDeliveryPoint, setSelfDeliveryPoint] = useState(0);

  const [deliverySurprise, setDS] = useState(true);
  const [call, setCall] = useState(false);

  const [deliveryAnon, setAnon] = useState(false);
  const [emptyAddr, setEmptyAddr] = useState(false);

  const [hasPostcard, setHasPostcard] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showNotify, setNotify] = useState(false);

  useEffect(() => {
    if (props.login.user) {
      setUser(props.login.user);
      setName(props.login.user.name);
      setPhone(props.login.user.phone);

      //setDiscount(props.login.user.discount);
    }
  }, [props.login.user, specialDiscount]);

  useEffect(() => {
    let dIntervalArr = excludeOldTime(date, OrderConstant.ORDER_TIME_TYPE);
    setDeliveryInterval(dIntervalArr);
    setTime(dIntervalArr[0]);

    props.getStoredUser();
    props.getStoredUserBasket(false);
    props.getSettingList();
    props.getStorageListByCat(5).then((val) => {
      setGifts(convertToList(val).reverse());
    });
    props.getCompositions().then((res) => setCompositionList(res));
  }, []);

  useEffect(() => {
    let dIntervalArr = excludeOldTime(date, OrderConstant.ORDER_TIME_TYPE);
    setDeliveryInterval(dIntervalArr);
    setTime(dIntervalArr[0]);
  }, [date]);

  useEffect(() => {
    setLoading(true);
    props.getIntervalList(formatDateYMD(new Date(date), "-")).then((data) => {
      setLoading(false);
      setDeliveryInterval(excludeOldTime(date, data));
    });
  }, [date]);

  useEffect(() => {
    let list = props.getStoredBasket();
    setBasket(list);

    if (
      list.filter((item) => {
        return item.title.toLowerCase().includes("открытка");
      }).length > 0
    ) {
      setHasPostcard(true);
    } else {
      setHasPostcard(false);
    }

    if (
      list.filter((item) => {
        return item.sale > 0;
      }).length > 0
    ) {
      setDiscount(0);
      setSpecialDiscount(true);
    } else {
      setSpecialDiscount(false);
    }
  }, [props.login.basketList, discount]);

  useEffect(() => {
    if (props.setting.list) {
      if (props.setting.list.length > 0) {
        if (settings.length == 0) setSettings(props.setting.list);
        //setFDP(props.getSettingValue("sum_busket_free_delivery"));

        setMinimalDeliveryPrice(
          props.getSettingValue("sum_delivery_zone_0", props.setting.list) > 0
            ? props.getSettingValue("sum_delivery_zone_0", props.setting.list)
            : minDeliveryPrice
        );
      }
    }
  }, [props.setting.list]);

  useEffect(() => {
    if (movingType == 1) {
      setDP(0);
    } else {
      if (distance == null) {
        let dp = minimalDeliveryPrice;
        setDP(dp);
      } else {
        let dp = props.getSettingDelivery(settings, distance);
        setDP(dp);
      }
    }
  }, [distance, discount, settings, basketList, movingType]);

  useEffect(() => {
    //todo: check this
    if (movingType == 0) {
      if (deliveryPrice == 0) {
        if (distance == 0 || distance == null) setDP(minimalDeliveryPrice);
        else if (distance > 0) setDP(minimalDeliveryPrice);
      }
    }
  }, [deliveryPrice]);

  function checkForm() {
    return (
      name.length > 0 &&
      phone.length > 7 &&
      (movingType != 0 || (time != "" && (emptyAddr || address.length > 0))) &&
      paymentType != null
    );
  }

  function checkAvailableWork() {
    var cDate = new Date();
    var f = true;

    if (
      cDate.getDate() == 31 &&
      cDate.getMonth() == 11 &&
      cDate.getHours() > 11
    )
      f = false;
    if (cDate.getDate() == 1 && cDate.getMonth() == 0 && cDate.getHours() < 14)
      f = false;

    return f;
  }

  function pay() {
    //todo: check this
    /*
    if (deliveryPrice == 0)
      setDP(
        getTotalPrice(basketList, 0, discount, false) >= freeDeliveryPrice
          ? 0
          : 250
      );
  */
    setLoading(true);
    let _address = "";
    if (!emptyAddr) {
      _address =
        _address +
        address +
        (addressHome.length > 0 ? " ,дом " + addressHome : "") +
        (addressApart.length > 0 ? " ,кв " + addressApart : "") +
        (addressFloor.length > 0 ? " ,этаж " + addressFloor : "") +
        (addressPod.length > 0 ? " ,подъезд " + addressPod : "") +
        (addressHCode.length > 0 ? " ,домофон " + addressHCode : "");
    } else {
      _address = "Узнать адрес у получателя";
    }
    let _comment = deliveryAnon ? "Анонимная доставка! " + comment : comment;

    let type = OrderConstant.ORDER_SELF_DELIVERY[selfDeliveryPoint];

    props
      .addOrder(
        {
          address_a: movingType == 0 ? _address : type,
          shipment_point: selfDeliveryPoint + 1,
          date: date,
          time: time,
          moving_types: movingType,
          payment_type: paymentType,
          price: getTotalPrice(
            basketList,
            deliveryPrice,
            discount,
            false,
            compositionList
          ),
          price_discount: discount,
          price_delivery: deliveryPrice,
          user_phone: phone,
          user_name: name,
          postcard: _comment,
          text: commentCurr,
          comment: commentFl,
          user_call: call ? 1 : 0,
        },
        basketList,
        user
      )
      .then((res) => {
        if (res) {
          props.clearUserBasket();

          navigate("/order/success");
        } else {
          showNotifyMsg("Прием заказов временно приостановлен");
        }
        setLoading(false);
      })
      .catch(() => {
        showNotifyMsg("Прием заказов временно приостановлен");
        setLoading(false);
      });
  }

  let basket = basketList.map((item, index) => { 
    return (
      <UiBasketCard
        key={index}
        image={item.image}
        title={item.title}
        text={item.text}
        price={item.price}
        totalPrice={getPrice(item, compositionList)}
        num={item.count}
        onClick={() => props.changeUserBasket(item.id, 0)}
        onPlus={() => props.changeUserBasket(item.id, item.count + 1)}
        onMinus={() => props.changeUserBasket(item.id, item.count - 1)}
      />
    );
  });

  var giftsList = gifts.map((item, index) => {
    if (index < 5)
      return (
        <UiGiftCard
          key={index}
          item={item}
          selected={props.checkInUserBasket(item.id)}
          onClick={() => {
            props.addUserBasket(item, true);
          }}
        />
      );
  });

  return (
    <PageContainer
      page="order"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
      alertOpen={true}
      alertTitle="Заголовок"
      alertText="Текст сообщения"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/order", label: "Корзина" }]}
        pageName="Оформление заказа"
      />
      <Container>
        <div className="order-wrap">
          <div className="basket-list">
            <h3>Моя корзина</h3>
            {basket.length > 0 ? (
              basket
            ) : (
              <div className="basket-list-empty">
                <UiIcon icon="bag" className="basket-list-empty-icon" />
                <h4>Ваша корзина пуста</h4>
                <UiBtnColor
                  color="gray"
                  onClick={() => navigate("/")}
                  small
                  text="В каталог"
                />
              </div>
            )}

            <p>
              Доставка на ближайшее время осуществляется в течении 2-х часов
              после оплаты. Доставка работает ежедневно с 8:00-21:00.
            </p>

            {basket.length > 0 ? (
              <div className="basket-list-coast">
                <p>Стоимость доставки</p>
                <p>
                  <strong>
                    {deliveryPrice > 0 ? deliveryPrice : "бесплатно"}
                  </strong>
                </p>
              </div>
            ) : null}
            {user ? (
              !specialDiscount ? (
                <div className="basket-list-coast">
                  <p>Ваша скидка на заказ составляет</p>
                  <p>
                    <strong>{discount}%</strong>
                  </p>
                </div>
              ) : (
                <div className="basket-list-coast">
                  <p>Ваша скидка включена в цену</p>
                  <p>
                    {" "}
                    <strong>10%</strong>{" "}
                  </p>
                </div>
              )
            ) : null}
            {basket.length > 0 && (
              <div className="basket-list-coast">
                <p>
                  <b>Общая стоимость заказа</b>
                </p>
                <p>
                  <b>
                    {getTotalPrice(
                      basketList,
                      deliveryPrice,
                      discount,
                      false,
                      compositionList
                    )}{" "}
                    ₽
                  </b>
                </p>
              </div>
            )}

            {basket.length > 0 && (
              <div className="basket-list-gifts">
                <p>Не забудьте добавить подарок</p>
                <div className="product-add-gifts">{giftsList}</div>
              </div>
            )}
          </div>
          {basket.length > 0 ? (
            <div className="order-info">
              <h3>Оформить заказ</h3>
              {user ? (
                !checkAvailableWork() ? (
                  <div className="order-info-input">
                    <UiBtnColor
                      color="pink"
                      onClick={() => navigate(`/`)}
                      text="Оформление заказов временно приостановлено"
                    />
                  </div>
                ) : (
                  <div className="order-info-input">
                    <div className="order-info-input-wrap">
                      <UiTextInput
                        label="Имя получателя"
                        value={name}
                        onChange={(val) => {
                          setName(val);
                        }}
                      />
                      <UiTextInputMasked
                        label="Номер получателя"
                        value={phone}
                        onChange={(val) => {
                          setPhone(val);
                        }}
                      />

                      <UiSelectOption
                        label="Тип доставка\самовывоз"
                        value={movingType}
                        optionList={toArrayList(
                          OrderConstant.ORDER_DELIVERY_TYPES_NAMES
                        )}
                        onChange={(e) => {
                          setMovingType(e);
                        }}
                      />
                      {movingType == 0 ? (
                        <div className="order-info-input-wrap">
                          <UiCheck
                            label="Доставка-сюрприз"
                            value={deliverySurprise}
                            onChange={() => {
                              setDS(!deliverySurprise);
                              setCall(false);
                            }}
                          />
                          <UiCheck
                            label="Предварительный звонок"
                            value={call}
                            onChange={() => {
                              setDS(false);
                              setCall(!call);
                            }}
                          />

                          <UiCheck
                            label="Анонимная доставка"
                            value={deliveryAnon}
                            onChange={() => {
                              setAnon(!deliveryAnon);
                            }}
                          />

                          <small>
                            в указанное вами время получатель должен быть дома
                          </small>

                          <UiSelectDate
                            label="Дата доставки"
                            value={date}
                            onChange={(e) => {
                              setDate(e);
                            }}
                          />

                          {deliveryInterval.length > 0 ? (
                            <UiSelectOption
                              label="Время доставки"
                              optionList={toArrayList(deliveryInterval)}
                              onChange={(e) => {
                                setTime(deliveryInterval[e]);
                              }}
                            />
                          ) : (
                            <small>в данный день нет доставки</small>
                          )}
                        </div>
                      ) : (
                        <div className="order-info-input-wrap">
                          <UiCheckList
                            colored={true}
                            checkList={OrderConstant.ORDER_SELF_DELIVERY_NAMES}
                            label="Адрес самовывоза:"
                            onChange={(val) => {
                              setSelfDeliveryPoint(val);
                            }}
                            selected={selfDeliveryPoint}
                          />
                        </div>
                      )}
                    </div>
                    <div className="order-info-input-wrap">
                      <div
                        id="ymap"
                        style={{ width: "500px", height: "500px" }}
                      ></div>
                      {movingType == 0 && !emptyAddr && (
                        <UiTextInputSV
                          label="Адрес доставки"
                          overflow
                          city={"Сургут"}
                          value={address}
                          callBack={(line, distance) => {
                            // console.log("res", address, line, distance)
                            setAddress(line);
                            setDistance(distance);
                          }}
                        />
                      )}
                      {movingType == 0 && (
                        <UiCheck
                          label="Узнать адрес у получателя"
                          value={emptyAddr}
                          onChange={() => {
                            setEmptyAddr(!emptyAddr);
                          }}
                        />
                      )}

                      <div className="order-info-input-row3">
                        {!emptyAddr && movingType == 0 && (
                          <UiTextInput
                            label="Дом"
                            onChange={(val) => {
                              setAddressHome(val);
                            }}
                          />
                        )}
                        {!emptyAddr && movingType == 0 && (
                          <UiTextInput
                            label="Подъезд"
                            onChange={(val) => {
                              setAddressPod(val);
                            }}
                          />
                        )}

                        {!emptyAddr && movingType == 0 && (
                          <UiTextInput
                            label="Квартира"
                            onChange={(val) => {
                              setAddressApart(val);
                            }}
                          />
                        )}
                      </div>

                      <div className="order-info-input-row2">
                        {!emptyAddr && movingType == 0 && (
                          <UiTextInput
                            label="Этаж"
                            onChange={(val) => {
                              setAddressFloor(val);
                            }}
                          />
                        )}
                        {!emptyAddr && movingType == 0 && (
                          <UiTextInput
                            label="Домофон"
                            onChange={(val) => {
                              setAddressHCode(val);
                            }}
                          />
                        )}
                      </div>
                      {hasPostcard && (
                        <UiTextArea
                          label="Открытка"
                          onChange={(val) => {
                            setComment(val);
                          }}
                        />
                      )}

                      <UiTextArea
                        label="Комментарий для флориста"
                        onChange={(val) => {
                          setCommentFl(val);
                        }}
                      />

                      {movingType == 0 && (
                        <UiTextArea
                          label="Комментарий для курьера"
                          onChange={(val) => {
                            setCommentCurr(val);
                          }}
                        />
                      )}
                      <UiBtnColor
                        color="accent"
                        onClick={() => {
                          if (checkForm()) pay();
                          else setNotify(true);
                          setTimeout(() => setNotify(false), 1000);
                        }}
                        text={`Заказать`}
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="order-info-input">
                  <UiBtnColor
                    color="accent"
                    onClick={() => navigate(`/login`)}
                    text="Войти в личный кабинет"
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>

        <UiLoader modalOpen={loading} />
        <UiNotify title="Заполните все поля" show={showNotify} />
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, setting, order }) => ({ storage, login, setting, order }),
  {
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,
    getCompositions: getCompositionsAction,

    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    getStoredUser: getStoredUserACtion,
    getStoredUserBasket: getStoredUserBasketAction,
    getStoredBasket: getStoredBasketAction,
    clearUserBasket: clearUserBasketAction,
    changeUserBasket: changeUserBasketAction,
    getSettingList: getSettingListAction,
    getSettingValue: getSettingValueAction,
    getSettingDelivery: getSettingDeliveryAction,
    getIntervalList: getIntervalListAction,

    getYandexMetricsBasket: getYandexMetricsBasketAction,

    getUserPurchases: getUserPurchasesAction,

    addOrder: addOrderAction,
  }
)(OrderPage);
