import React, { useState } from "react";

import UiIcon from "../icon/UiIcon";
import UiSelect from "../forms/UiSelect";
import StorageConstant from "../../constants/StorageConstant";

import "./UiCatalog.scss";

const UiCatalogFilter = (props) => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [activeSize, setActiveSize] = useState(0);
  const [activePrice, setActivePrice] = useState(0);
  
  const filtersList = StorageConstant.TAGS_ARRAY_FILTER;
  const pricesList = [
    "Любая цена",
    "0₽ - 2000₽",
    "2000₽ - 3000₽",
    "3000₽ - 5000₽",
    "5000₽ - 10000₽",
    "более 10000₽",
  ];
  const sizesList = [
    "Все размеры",
    "S (20 - 30 см)",
    "M (30 - 40 см)",
    "L (40 - 50 см)",
    "XL (50 - 60 см)",
  ];

  const setFilter = (val) => {
    let newVal = val;
    setActiveFilter(newVal);
    props.onChange(newVal);
  };

  var filters = filtersList.map((item, index) => {
    return (
      <button
        key={index}
        className={
          "btn catalog-filter" + (activeFilter === index ? " active" : "")
        }
        onClick={() => setFilter(index)}
      >
        {item.text}
      </button>
    );
  });

  return (
    <div className="catalog-filters">
      <div className="category-filters-tab">{filters}</div>
      <div className="category-filters-select">
        {/*
        <UiSelect 
          onChange={(val) => { 
            setActiveSize(val)
            props.onChangeSize(val);
          }}
          selected={sizesList[activeSize]}
          selectItems={sizesList}
          small={true}
        />
        */}
        <UiSelect
          onChange={(val) => { 
            setActivePrice(val)
            props.onChangePrice(val);
          }}
          selected={pricesList[activePrice]}
          selectItems={pricesList}
          small={true}
        />
      </div>
    </div>
  );
};
export default UiCatalogFilter;
