import React from "react";
import { Helmet } from "react-helmet";

import UiPageTitle from "../../components/header/UiPageTitle";

import "../../styles/app.scss";

export default function PageContainer({ children, ...props }) {
  return (
    <div className={"page" + (props.page ? " " + props.page : "")}>
      <Helmet>
        <title>{props.pageTitle}</title>
        <meta name="description" content={props.pageDescription} />
        {props.pageKeywords && props.pageKeywords != "" && <meta name="keywords" content={props.pageKeywords} /> }
      </Helmet>
      {props.linksList ? (
        <UiPageTitle linksList={props.linksList} pageName={props.pageName} />
      ) : null}
      {children}
    </div>
  );
}
