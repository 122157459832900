import React, { useState } from "react";

import UiIcon from "../icon/UiIcon";

import "./UiSelect.scss";

const UiSelect = (props) => {
  const [open, setOpen] = useState(false);

  const setValue = (val) => {
    let newVal = val;
    setOpen(false);
    props.onChange(newVal);
  }

  var selectItems = props.selectItems.map((item, index) => {
    return (
      <button
        key={index}
        onClick={() => setValue(index)}
        className="select-option"
      >
        {item}
      </button>
    );
  });

  return (
    <div className={"select-wrap" + (props.small ? " small" : "")}>
      <button className="select" onClick={() => setOpen(!open)}>
        <span>{props.selected}</span>
        <UiIcon icon="caret" fill="black" size="8" />
      </button>
      <div className={"select-list" + (open ? " open" : "")}>
        {selectItems}
      </div>
    </div>
  );
};
export default UiSelect;
