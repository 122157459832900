import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';

import "react-datepicker/dist/react-datepicker.css";
import "./UiSelectDate.scss";

const UiSelectDate = (props) => {
  const [maxDate, setDate] = useState(new Date());
  useEffect(() => {
    registerLocale('ru', ru);

    let nextWeek = new Date()
    nextWeek.setDate(nextWeek.getDate() + 7)
    setDate(nextWeek);
  }, []);

 


  return (
    <div className="select-date">
      <label>{props.label}</label>
      <DatePicker
        locale="ru"
        dateFormat="dd.MM.yyyy"
        clearIcon={null}
        selected={props.value}
        minDate={new Date()}
        maxDate={maxDate}
        onChange={(e) => {
          props.onChange(e)
        }}
      />
      {props.warnText && props.warnText != "" ? (
        <p className="text-input-warn">* {props.warnText}</p>
      ) : null}
    </div>
  );
};
export default UiSelectDate;
