import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getMyOrders as getMyOrdersAction } from "../../redux/modules/order";
import {
  logoutUser as logoutUserAction,
  updateUser as updateUserAction,
} from "../../redux/modules/login";

import OrderConstant from "../../constants/OrderConstant";

import { ReactComponent as SvgInfo } from "../../assets/icons/alert/info.svg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiHistoryCard from "../../components/cards/UiHistoryCard";
import UiIcon from "../../components/icon/UiIcon";
import UiTextInput from "../../components/forms/UiTextInput";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Profile.scss";

function ProfilePage(props) {
  const user = true;
  let navigate = useNavigate();

  const [name, setName] = useState("");
  const [listOrders, setListOrders] = useState([
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
  ]);

  useEffect(() => {
    props.getMyOrders();
    if (props.login.user) setName(props.login.user.name);
  }, [props.login.user]);

  useEffect(() => {
    if (props.order.ordersList.length > 0)
      setListOrders(props.order.ordersList);
    else setListOrders([]);
  }, [props.order.ordersList]);

  let orders = listOrders.map((item, index) => {
    return (
      <UiHistoryCard
        key={index}
        skeleton={item.loader}
        date={item.date}
        info={item.items}
        price={item.price}
        status={item.status}
        statusName={OrderConstant.ORDER_TYPES_NAMES[item.status]}
      />
    );
  });

  return (
    <PageContainer
      page="profile"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/profile", label: "Профиль" }]}
        pageName="Мой профиль"
        wide={true}
      />
      <Container>
        {user ? (
          <div className="profile-wrap">
            <div className="profile-info">
              <button className="profile-avatar">
                <UiIcon fill="dark" icon="user" size="24" />
              </button>
              <div className="profile-sale">
                <p>
                  Ваша скидка:{" "}
                  {`${props.login.user ? props.login.user.discount : 0}`}%
                </p>
                <button className="profile-sale-button">
                  <SvgInfo className="profile-sale-button-icon" />
                </button>
              </div>
              {props.login.user ? (
                <UiTextInput
                  disabled
                  label="Мой телефон"
                  value={`+${props.login.user.phone}`}
                />
              ) : null}
              <h3>Информация</h3>
              <UiTextInput
                label="Ваше имя"
                value={name}
                onChange={(val) => {
                  setName(val);
                }}
              />

              <UiBtnColor
                color="pink"
                text="Сохранить изменения"
                onClick={() => {
                  let _data = props.login.user;
                  _data.name = name;
                  props.updateUser(_data).then(() => {});
                }}
              />
              <UiBtnColor
                color="white"
                text="Выйти"
                onClick={() => {
                  props.logoutUser().then(() => {
                    navigate(`/`);
                  });
                }}
              />
            </div>
            <div className="profile-orders">
              <h3>Мои заказы</h3>
              <p>
                Для отмены, изменения или уточнения деталей заказа - свяжитесь с
                нашим менеджером по телефону:{" "}
                <a href="tel:79128176377">8&nbsp;(912)&nbsp;817-63-77</a>
              </p>
              <div className="profile-orders-list">{orders}</div>
            </div>
          </div>
        ) : null}
      </Container>
    </PageContainer>
  );
}

export default connect(({ login, order }) => ({ login, order }), {
  getMyOrders: getMyOrdersAction,
  logoutUser: logoutUserAction,
  updateUser: updateUserAction,
})(ProfilePage);
