import React, { useState } from "react";

import "./UiCategoriesList.scss";

const UiCategoriesList = (props) => {
  const [activeFilter, setActiveFilter] = useState(null);

  const changeCategory = (val) => {
    let n_val = val;
    setActiveFilter(n_val);
    props.onChange(n_val);
  };

  var categories = props.categoriesList.map((item, index) => {
    return (
      <button
        key={index}
        className={
          "catalog-categories-card" + (index === activeFilter ? " active" : "")
        }
        onClick={() => changeCategory(activeFilter != index ? index : null )}
      >
        <h3>{item.title}</h3>
      </button>
    );
  });

  return (
    <div className="catalog-categories-wrap">
      <div className="catalog-categories">{categories}</div>
    </div>
  );
};
export default UiCategoriesList;
