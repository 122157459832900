import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import StorageConstant from "../../constants/StorageConstant";

import UiCatalogFilter from "./UiCatalogFilter";
import UiBtnColor from "../buttons/UiBtnColor";

import "./UiCatalog.scss";

const UiCatalogCard = React.lazy(() => import("../cards/UiCatalogCard"));

const filtersList = StorageConstant.TAGS_ARRAY_FILTER;

const UiCatalog = (props) => {
  const pricesList = [
    0,
    [0, 2000],
    [2000, 3000],
    [3000, 5000],
    [5000, 10000],
    [10000, 99999],
  ];

  const sizeList = [0, [20, 30], [30, 40], [40, 50], [50, 60]];

  let navigate = useNavigate();
  const [activeSize, setActiveSize] = useState(0);
  const [activePrice, setActivePrice] = useState(0);
  const [activeTag, setActiveTag] = useState(filtersList[0].text);

  function checkFilter(arr) {
    let _arr = [];
    arr.map((item) => {
      if (activePrice == 0 && activeTag == filtersList[0].text) {
        _arr.push(item);
      } else {
        if (activePrice > 0) {
          if (
            item.price >= pricesList[activePrice][0] &&
            item.price <= pricesList[activePrice][1]
          ) {
            _arr.push(item);
          }
        } else if (activeTag != filtersList[0].text) {
          if (item?.status?.includes(activeTag)) {
            _arr.push(item);
          }
        }
      }
    }); /*
    arr.map((item) => {
      if(activeSize == 0 ){
        _arr.push(item)
      } else if(item.price >= sizeList[activeSize][0] &&  item.price <= sizeList[activeSize][1]){
        _arr.push(item)
      }
    })
    */
    return _arr;
  }

  var catalogList = checkFilter(props.list).map((item, index) => {
    return (
      <UiCatalogCard
        key={index}
        image={item.image}
        onClick={() => navigate(`/product/${item.title_url}`)}
        price={item.sale > 0 ? `от ${item.sale}` : item.price}
        status={item.status}
        title={item.title}
        skeleton={item.loader}
        unavailable={item.is_unavailable}
      />
    );
  });

  return (
    <div
      className={
        "catalog-wrap" +
        (props.gray ? " gray" : "") +
        (props.categoriesLeft ? " categories-left" : "")
      }
    >
      <h3>{props.title}</h3>
      {props.subtitle ? <p>{props.subtitle}</p> : null}
      {props.filters ? (
        <UiCatalogFilter
          onChangePrice={(val) => {
            setActivePrice(val);
          }}
          onChangeSize={(val) => {
            setActiveSize(val);
          }}
          onChange={(val) => {
            setActiveTag(filtersList[val].text);
          }}
        />
      ) : null}
      <div className="catalog-list">
        {catalogList}
        {catalogList.length == 0 ? (
          <p>Ничего не найдено. Попробуйте сбросить фильтр.</p>
        ) : null}
      </div>
    </div>
  );
};
export default UiCatalog;
