import React from "react";

import { ReactComponent as SvgAdd } from "../../assets/icons/ui/add.svg";
import { ReactComponent as SvgArrowNext } from "../../assets/icons/ui/arrow-next.svg";
import { ReactComponent as SvgArrowRight } from "../../assets/icons/ui/arrow-right.svg";
import { ReactComponent as SvgBag } from "../../assets/icons/ui/bag.svg";
import { ReactComponent as SvgCall } from "../../assets/icons/ui/call.svg";
import { ReactComponent as SvgCaret } from "../../assets/icons/ui/caret.svg";
import { ReactComponent as SvgChat } from "../../assets/icons/ui/chat.svg";
import { ReactComponent as SvgCheck } from "../../assets/icons/ui/checked.svg";
import { ReactComponent as SvgClipboard } from "../../assets/icons/ui/clipboard.svg";
import { ReactComponent as SvgClock } from "../../assets/icons/ui/clock.svg";
import { ReactComponent as SvgClose } from "../../assets/icons/ui/close.svg";
import { ReactComponent as SvgDown } from "../../assets/icons/ui/select-down.svg";
import { ReactComponent as SvgFlower } from "../../assets/icons/ui/flower.svg";
import { ReactComponent as SvgInstagram } from "../../assets/icons/social/instagram.svg";
import { ReactComponent as SvgMenu } from "../../assets/icons/ui/menus.svg";
import { ReactComponent as SvgMessage } from "../../assets/icons/ui/message.svg";
import { ReactComponent as SvgPin } from "../../assets/icons/ui/pin.svg";
import { ReactComponent as SvgRemove } from "../../assets/icons/ui/remove.svg";
import { ReactComponent as SvgScan } from "../../assets/icons/ui/scan.svg";
import { ReactComponent as SvgSelectDown } from "../../assets/icons/ui/select-down.svg";
import { ReactComponent as SvgSelectLeft } from "../../assets/icons/ui/select-left.svg";
import { ReactComponent as SvgSelectRight } from "../../assets/icons/ui/select-right.svg";
import { ReactComponent as SvgSelectUp } from "../../assets/icons/ui/select-up.svg";
import { ReactComponent as SvgTelegram } from "../../assets/icons/social/telegram.svg";
import { ReactComponent as SvgUp } from "../../assets/icons/ui/select-up.svg";
import { ReactComponent as SvgUser } from "../../assets/icons/ui/user.svg";
import { ReactComponent as SvgViber } from "../../assets/icons/social/viber.svg";
import { ReactComponent as SvgVk } from "../../assets/icons/social/vk.svg";
import { ReactComponent as SvgWhatsapp } from "../../assets/icons/social/whatsapp.svg";

import "./UiIcon.scss";

const UiIcon = (props) => {
  const iconClass =
    "svg-icon" +
    (props.className ? " " + props.className : "") +
    (props.fill ? " " + props.fill : "");
  const iconStyle = { width: props.size, height: props.size };

  return props.icon === "add" ? (
    <SvgAdd className={iconClass} style={iconStyle} />
  ) : props.icon === "arrow-right" ? (
    <SvgArrowRight className={iconClass} style={iconStyle} />
  ) : props.icon === "arrow-next" ? (
    <SvgArrowNext className={iconClass} style={iconStyle} />
  ) : props.icon === "bag" ? (
    <SvgBag className={iconClass} style={iconStyle} />
  ) : props.icon === "call" ? (
    <SvgCall className={iconClass} style={iconStyle} />
  ) : props.icon === "caret" ? (
    <SvgCaret className={iconClass} style={iconStyle} />
  ) : props.icon === "chat" ? (
    <SvgChat className={iconClass} style={iconStyle} />
  ) : props.icon === "check" ? (
    <SvgCheck className={iconClass} style={iconStyle} />
  ) : props.icon === "clipboard" ? (
    <SvgClipboard className={iconClass} style={iconStyle} />
  ) : props.icon === "clock" ? (
    <SvgClock className={iconClass} style={iconStyle} />
  ) : props.icon === "close" ? (
    <SvgClose className={iconClass} style={iconStyle} />
  ) : props.icon === "down" ? (
    <SvgDown className={iconClass} style={iconStyle} />
  ) : props.icon === "flower" ? (
    <SvgFlower className={iconClass} style={iconStyle} />
  ) : props.icon === "instagram" ? (
    <SvgInstagram className={iconClass} style={iconStyle} />
  ) : props.icon === "menu" ? (
    <SvgMenu className={iconClass} style={iconStyle} />
  ) : props.icon === "message" ? (
    <SvgMessage className={iconClass} style={iconStyle} />
  ) : props.icon === "pin" ? (
    <SvgPin className={iconClass} style={iconStyle} />
  ) : props.icon === "remove" ? (
    <SvgRemove className={iconClass} style={iconStyle} />
  ) : props.icon === "scan" ? (
    <SvgScan className={iconClass} style={iconStyle} />
  ) : props.icon === "select-down" ? (
    <SvgSelectDown className={iconClass} style={iconStyle} />
  ) : props.icon === "select-left" ? (
    <SvgSelectLeft className={iconClass} style={iconStyle} />
  ) : props.icon === "select-right" ? (
    <SvgSelectRight className={iconClass} style={iconStyle} />
  ) : props.icon === "select-up" ? (
    <SvgSelectUp className={iconClass} style={iconStyle} />
  ) : props.icon === "telegram" ? (
    <SvgTelegram className={iconClass} style={iconStyle} />
  ) : props.icon === "up" ? (
    <SvgUp className={iconClass} style={iconStyle} />
  ) : props.icon === "user" ? (
    <SvgUser className={iconClass} style={iconStyle} />
  ) : props.icon === "viber" ? (
    <SvgViber className={iconClass} style={iconStyle} />
  ) : props.icon === "vk" ? (
    <SvgVk className={iconClass} style={iconStyle} />
  ) : props.icon === "whatsapp" ? (
    <SvgWhatsapp className={iconClass} style={iconStyle} />
  ) : null;
};
export default UiIcon;
