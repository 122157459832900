import ENV from './Env.js';


export const getSlider = () => {
  return fetch(`${ENV.API_URL}/material/slider/all`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}


export const getItemTitle = (_title) => {
  return fetch(`${ENV.API_URL}/storage/show/title/${_title}`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}

export const getListByArray = ( _data) => { 
  return fetch(`${ENV.API_URL}/storage/show/array`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const getListByCat = (_id) => {
  return fetch(`${ENV.API_URL}/storage/category/${_id}`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}

export const getList = () => {
  return fetch(`${ENV.API_URL}/storage/all`, {
    method: 'GET',
    headers: ENV.getHeaders(),
  }).then((response) => {
    return response.json()
  });
}


export const makeStorage = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/storage/store`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateStorage = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/storage/update/${_data['id']}`, {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const getStorageComposition = (_data) => {
  return fetch(`${ENV.API_URL}/composition/storage/show/${_data.id}`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}


export const getCompositionList = () => {
  return fetch(`${ENV.API_URL}/composition/list/show`, {
      method: 'GET',
      headers: ENV.getHeaders(), 
  }).then(function (response) {
      return response.json();
  });

}