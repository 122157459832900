import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import Env from "../../services/Env";
import UiIcon from "../icon/UiIcon";

import "./UiSliderPromo.scss";

const UiSliderPromo = (props) => {
  const componentRef = useRef();
  let navigate = useNavigate();

  const { width, height } = useContainerDimensions(componentRef);
  const activeW = width / 2;
  const disW = width / 4 - 10;
  const fullActiveW = width;

  const [activeSlide, setActiveSlide] = useState(0);
  const currentTimer = useRef();

  const changeSlide = (val) => {
    let length = props.promoList.length; 
    if (val == 1 && length - 1 > activeSlide) {
      setActiveSlide(activeSlide + 1);
    } else if (val == 1 && length - 1 == activeSlide) {
      setActiveSlide(0);
    } else if (val == 0 && activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    } else if (val == 0 && activeSlide == 0) { 
      setActiveSlide(length - 1);
    }
    clearTimeout( currentTimer.current)
  };

  useEffect(() => {
    if (props.promoList.length > 1) {
      currentTimer.current = setTimeout(() => {
        changeSlide(0);
      }, 8000);
    }
  }, [props.promoList, activeSlide]);

  var slides = props.promoList.map((item, index) => {
    return (
      <div
        key={index}
        className={
          "slider-promo-slide" + (index === activeSlide ? " active" : "")        
        }
        onClick={() => {
          if (item.url) navigate(`${item.url}`);
        }}
      >
        <div
          className="slider-promo-slide-image"
          style={{
            backgroundImage: "url(" + Env.PUBLIC_URL + item.image + ")",
          }}
        ></div>
      </div>
    );
  });

  return (
    <div ref={componentRef} className="slider-promo-wrap">
      {width ? (
        <div className={`slider-promo sl-${props.promoList.length}`}>
          <div className="slider-promo-scroll">{slides}</div>
          <div className="slider-promo-btns">
            <button
              className="slider-promo-btn prev"
              onClick={() => changeSlide(0)}
            >
              <UiIcon fill="accent" icon="select-left" size={20} />
            </button>
            <button
              className="slider-promo-btn next"
              onClick={() => changeSlide(1)}
            >
              <UiIcon fill="dark" icon="select-right" size={20} />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default UiSliderPromo;
