import React, {useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  getSettingDelivery as getSettingDeliveryAction,
} from "../../redux/modules/setting";

import { ReactComponent as SvgCard } from "../../assets/icons/payment/card.svg";
import { ReactComponent as SvgCash } from "../../assets/icons/payment/cash.svg";
import { ReactComponent as SvgCorrect } from "../../assets/icons/payment/correct.svg";
import { ReactComponent as SvgFund } from "../../assets/icons/payment/fund.svg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";

import UiPageTitle from "../../components/header/UiPageTitle";

import "./Static.scss";

const UiPaymentCard = (props) => {
  return (
    <div className="delivery-info-payment-item">
      <div className="delivery-info-payment-item-image">
        {props.image === "card" && <SvgCard />}
        {props.image === "cash" && <SvgCash />}
        {props.image === "correct" && <SvgCorrect />}
        {props.image === "fund" && <SvgFund />}
      </div>
      <div className="delivery-info-payment-item-info">
        <p>
          {props.text} <span>{props.subtext}</span>
        </p>
      </div>
    </div>
  );
};

const UiDeliveryCard = (props) => {
  return (
    <div className="delivery-card">
      <div className="delivery-card-info">
        <p>{props.info}</p>
      </div>
      <div className="delivery-card-pay">
        <p>
          {props.pay} <span>руб</span>
        </p>
      </div>
    </div>
  );
};

function DeliveryPage(props) {

  const [settings, setSettings] = useState([]);

  useEffect(() => { 
    props.getSettingList().then((setting) => {
      if (setting) {
        if (setting.length > 0) {
          setSettings(setting);
        }
      }
    }); 
  }, []);



  return (
    <PageContainer
      page="delivery"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/delivery", label: "Оплата и доставка" }]}
        pageName="Информация об оплате и доставке"
      />
      <Container>
        <div className="delivery-wrap">
          <div className="delivery-info-list">
            <div className="delivery-info-payment">
              <h3>Методы оплаты</h3>
              <div className="delivery-info-payment-list">
                <UiPaymentCard
                  image="cash"
                  text="Оплата наличными в магазине"
                />
                <UiPaymentCard
                  image="card"
                  text="Оплата банковской картой в магазине"
                />
                <UiPaymentCard
                  image="correct"
                  text="Оплата с помощью банковской карты на сайте"
                />
                <UiPaymentCard
                  image="fund"
                  text="Оплата переводом на карту Сбербанк"
                  subtext="(номер карты вышлет
                    администратор при приеме заказа)"
                />
              </div>
            </div>
            <div className="delivery-info">
              <h3>ДОСТАВКА</h3>
              <p>
                Доставка из наших магазинов работает с 8:00 до 21:00 и
                осуществляется с временным интервалом 1 час.
              </p>
              <p>Стоимость интервальной доставки по городу {props.getSettingValue("sum_delivery_zone_0" , settings)} рублей.</p>
              <p>Доставка к точному времени оплачивается в двойном тарифе.</p>
              <p>Стоимость доставки за город согласовывается отдельно.</p>
              <p>Самовывоз из магазинов возможен с 8:00-20:00 ежедневно.</p>

              <h3>Доставка по районам</h3>

              <p>Районы и цены доставки могут изменяться. </p>
              <p>
                <b>
                  Доставка в не указанные районы осуществляется по
                  индивидуальным тарифам.
                </b>
              </p>
              <p>
                <b>
                  Для получения финальной стоимости доставки свяжитесь с нами.
                  Или сделайте онлайн заказ и наш менеджер сам свяжется с вами
                  для уточнения деталей и стоимости доставки.
                </b>
              </p>

              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Aac817515216511a335f8253f520dae9ffd8c29bbe7f2535bf6f698973f271055&amp;source=constructor"
                width="100%"
                height="500"
                frameborder="0"
              ></iframe>

              <div className="delivery-info-cards-list">
                <UiDeliveryCard info="Город" pay={props.getSettingValue("sum_delivery_zone_0" , settings)} />

                <UiDeliveryCard info="Белый Яр, Юность, Снежный" pay={props.getSettingValue( "sum_delivery_zone_9"  , settings)} />

                <UiDeliveryCard
                  info="Солнечный, Барсово, Дорожный, МК-37, Дружба, Май, Ягодное, Сириус, Берендей, Таежный, Тихий бор, Аэропорт"
                  pay={props.getSettingValue(  "sum_delivery_zone_8" , settings)}
                />

                <UiDeliveryCard
                  info="Крылья Сургута, Авиатор-34, Энергетик-2, Лесной, Прибрежный-1, Прибрежный-2, Прибрежный-3, Энергостроитель"
                  pay={props.getSettingValue(  "sum_delivery_zone_11"  , settings)}
                />

                <UiDeliveryCard info="Победит-1, Победит-2" pay={props.getSettingValue( "sum_delivery_zone_12"  , settings)} />

                <UiDeliveryCard info="Федоровский, Нефтеюганск" pay={props.getSettingValue ( "sum_delivery_zone_2"  , settings )} />

                <UiDeliveryCard
                  info="Изменения адреса доставки, если по ранее указанному адресу
                получателя не было (УСЛУГА ВОЗМОЖНА ТОЛЬКО ПО ГОРОДУ, АУРА И ЖД)"
                pay={props.getSettingValue ( "sum_delivery_change"  , settings )}
                />
              </div>
            </div>
          </div>
          <div className="delivery-info-list">
            <h3>ОПЛАТА И УСЛОВИЯ ОФОРМЛЕНИЯ ЗАКАЗОВ</h3>
            <p>
              После согласования заказа с администратором интернет магазина,
              заказ оплачивается 100% (задаток) с последующим предоставлением
              чека об оплате, после чего заказ считается принятым в работу.
            </p>
            <h3>ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ И ОБМЕН ТОВАРА</h3>
            <p>
              Цветы являются живым товаром. К отношениям между Клиентом и
              Продавцом применяется право Российской федерации. В соответствии с
              Законом Российской федерации «О защите прав потребителей» от
              07.02.1992 N. 2300-1 (е ред, от 25.10.2007 г.) и Постановлением
              Правительства Российской федерации от 19.01.1998 N55 (в ред.
              27.03.2007 г.) срезанные цветы и горшечные растения обмену и
              возврату не подлежат (указаны в Перечне непродовольственных
              товаров надлежащего качества, не подлежащих возврату или обмену).
            </p>
            <p>
              Покупатель имеет право отказаться от получения товара в момент
              доставки или в момент выдачи заказа в салоне при самовывозе, если
              товар оказался ненадлежащего качества (на основании п.3 ст. 497 ГК
              РФ, статья 21 Закона «О защите прав потребителей»). Проверяйте,
              пожалуйста, купленную продукцию в магазине или во время доставки в
              присутствии курьера.
            </p>
            <p>
              В случае, если Заказчик оформил и оплатил заказ, но решил по
              какой-либо причине отказаться от заказа, а флорист уже собрал
              композицию или букет, то расходы, которые компания к тому моменту
              успела уже понести - отменить невозможно, такие расходы
              оплачиваются самим Заказчиком.
            </p>
            <p>
              В случае, если Заказчику был продан товар ненадлежащего качества,
              то получить возврат денежных средств или обмен на другой товар по
              эквивалентной стоимости можно в одном из салонов компании, после
              обращения с претензией любым удобным способом (звонок по телефону,
              сообщение в вайбер или WhatsApp, сообщение в социальные сети) с
              предоставлением фотографии, подтверждающей факт ненадлежащего
              качества товара.
            </p>
            <p>
              Свежесрезанные цветы - товар скоропортящийся, поэтому все вопросы
              и претензии по качеству принимаются только в течение 24 часов с
              момента осуществления покупки или получения заказа.
            </p>
            <p>
              В случае принятия решения о возврате денежных средств, возврат
              осуществляется соответственно способу приема денег от заказчика.
              При оплате товара банковской картой возврат денежных средств
              возможен только на карту, с которой была совершена операция, после
              электронной оплаты оформить возврат наличными нельзя. Сроки
              зачисления могут варьироваться от 3-40 рабочих дней, в зависимости
              от Банка.
            </p>
            <p>
              Работы на сайте являются лишь возможными вариантами изготовления
              цветочных букетов (кроме раздела онлайн витрина). Каждая
              изготавливаемая флористом цветочная композиция является
              уникальной. Состав букета и упаковка могут быть изменены в
              зависимости от ассортимента на момент заказа. Значительные замены
              цветов в процессе согласования заказа с заказчиком - оговариваются
              дополнительно. При этом каждая цветочная композиция составляется
              из свежих цветов, и флористы предпринимают все усилия для того,
              чтобы сохранить цветовую гамму и стиль букета.
            </p>
            <h3>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h3>
            <p>
              Администрация cайта ___ (далее «Сайт») очень серьезно относится к
              защите персональных данных посетителей нашего Сайта и никогда не
              предоставляет персональную информацию посетителей кому бы то ни
              было, кроме случаев, когда этого прямо требует уполномоченный
              государственный орган (например, по письменному запросу суда). Вся
              персональная информация посетителя используются для связи с ним,
              для исполнения сделки, заключенной между посетителем Сайта с
              помощью ресурсов Сайта, для анализа посещаемости Сайта, для
              разработки предложений по его улучшению и развитию и может быть
              раскрыта иным третьим лицам только с его разрешения.
            </p>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ setting }) => ({ setting }),
  { 
    getSettingList: getSettingListAction,
    getSettingValue: getSettingValueAction,
    getSettingDelivery: getSettingDeliveryAction, 
  }
)(DeliveryPage);
