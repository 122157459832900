import React from "react";
import Skeleton from "react-loading-skeleton";

import UiIcon from "../icon/UiIcon";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiCareCard.scss";

const UiCardItem = (props) => {
  return (
    <div className="care-card-item">
      <UiIcon fill="brand" icon="flower" size={12} />
      <p>
        {props.text}
      </p>
    </div>
  );
};

const UiCareCard = (props) => {
  const careList1 = [
    { text: "Поставьте композицию в максимально прохладное место, не ставьте ее на солнце, рядом с отопительными приборами, на подоконнике или рядом с фруктами." },
    { text: "Поливайте композицию ЕЖЕДНЕВНО, если в помещении сухо и жарко - несколько раз в день." },
    { text: "Помните, что губка, которая находится внутри композиции, должна быть всегда влажной. Количество воды зависит от объема композиции." },
    { text: "Не доставайте цветы из губки без надобности." },
    { text: "Помните, что подрезать цветы в композиции не нужно!" },
    { text: "При минусовой температуре на улице используйте утеплитель (одной минуты без утеплителя достаточно для заморозки цветка)." },
  ];

  const careList2 = [
    { text: "Подберите вазу исходя из объема и высоты букета. Букет должна стоять свободно, уровень воды должен быть не менее 1/2 высоты ствола. Если подходящей вазы вы не найдете, подрезайте розу, чтобы ее половина находилась в воде, если букет объемный и цветы стоят тесно - разделите букет." },
    { text: "Перед тем как поставить цветы в вазу необходимо подрезать стебель на 1-2 см под углом 45 градусов ножом или секатором. Не используйте ножницы!" },
    { text: "Ежедневно меняйте воду, мойте вазу и стволы у цветов." },
    { text: "Через день подрезайте стебель и освежайте срез." },
    { text: "Если роза начинает увядать, подрезайте ее на 10-15 см, наполните полную вазу свежей водой. Так путь воды к бутону сократиться и роза поднимет бутон." },
    { text: "Не ставьте цветы на сквозняках, прямых солнечных лучах, рядом с нагревательными приборами (не ставьте цветы на подоконник)." },
    { text: "При минусовой температуре на улице используйте утеплитель (одной минуты без утеплителя достаточно для заморозки цветка)." },
  ];

  var items1 = careList1.map((item, index) => {
    return <UiCardItem key={index} index={index} text={item.text} />;
  });

  var items2 = careList2.map((item, index) => {
    return <UiCardItem key={index} index={index} text={item.text} />;
  });

  if (props.skeleton) {
    return <Skeleton count={1} width={"100%"} height={100} />;
  } else {
    return (
      <div className="care-card">{props.type === 1 ? items1 : items2}</div>
    );
  }
};
export default UiCareCard;
