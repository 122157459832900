import React, { useState, useEffect } from "react";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBarNum from "../../components/bars/UiBarNum";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheck from "../../components/forms/UiCheck";
import UiCheckList from "../../components/forms/UiCheckList";
import UiGiftCard from "../../components/cards/UiGiftCard";
import showNotify from "../../components/notify/UiNotify";

import {
  getCompositionByStorage as getCompositionByStorageAction,
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
  getCompositions as getCompositionsAction,
} from "../../redux/modules/storage";
import { getSubCategory as getSubCategoryAction } from "../../redux/modules/category";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
  changeUserBasket as changeUserBasketAction,
} from "../../redux/modules/login";

import Env from "../../services/Env";

import "react-loading-skeleton/dist/skeleton.css";
import "./productStyle.scss";

import { convertImages, unCheckInArray, inArray } from "../../utilities/array";
import { convertToList } from "../../utilities/storage";
import { getProductPrice, getProductSale } from "../../utilities/order";
import UiCareCard from "../../components/cards/UiCareCard";

function ProductPage(props) {
  let { title } = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [item, setItem] = useState(null);
  const [subCat, setSubCat] = useState(null);
  const [image, setImage] = useState(0);
  const [type, setType] = useState(0);
  const [selectedNum, setSelectedNum] = useState(1);

  const images = [];
  const [productTypes, setProductsTypes] = useState([]);
  const [alternatives, setAlternatives] = useState([]);
  const [compositionList, setCompositionList] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [flowers, setFlowers] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [addBasket, setAddBasket] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoader(true);
    props.getStorageItem(title).then((val) => {
      if (val) {
        setItem(val);
        props.getSubCategory(val.sub_category).then((res) => {
          if (res.length > 0) setSubCat(res[0]);
        });
        props.getCompositions().then((res) => setCompositionList(res));

        props.getCompositionByStorage(val.id).then((res) => {
          if (res.response) {
            if (res.response.length > 0) {
              let arr = [];
              res.response.map((item, index) => {
                arr.push({
                  option: val.title + " | " + item.custom_title,
                  price: item.custom_price,
                  composition: item.custom_composition,
                  custom_composition: item.custom_composition,

                  sale: item.custom_sale,
                  sale_count: item.custom_sale_count,

                  storage_id: val.id,
                  image: item.custom_image,
                  text: item.custom_text,
                  id: item.id,
                  index: index + 1,
                });
              });
              if (arr.length == 0) {
                arr.push({
                  option: val.title,
                  price: val.price,
                  storage_id: val.id,
                  composition: val.composition,
                  custom_composition: val.composition,
                  id: null,
                  index: 0,
                });
              }
              setItem({
                ...item,
                image: convertImages(arr[0].image)[0],
                images: arr[0].image,
                custom: arr[0].id,
                price: arr[0].price,
                custom_price: arr[0].price,
                sale: arr[0].sale,
                sale_count: arr[0].sale_count,
                custom_composition: arr[0].custom_composition,
                composition: res.response.length > 0 ? arr[0].composition : [],
                storage_id: arr[0].id,

                title: arr[0].option,
                text: arr[0].text,
              });

              setProductsTypes(arr);
            }
          }
        });
      } else {
        navigate(`/`);
      }
    });
    props.getStorageListByCat(26).then((val) => {
      setGifts(convertToList(val));
    });
    props.getStorageListByCat(4).then((val) => {
      setFlowers(convertToList(val));
    });
    props.getStorageListByCat(7).then((val) => {
      setAdditional(convertToList(val).reverse());
    });
  }, [location]);

  useEffect(() => {
    if (item) {
      setLoader(false);
    }
  }, [item]);

  var imageButtons = (item ? convertImages(item.imgs) : []).map(
    (item, index) => {
      return (
        <button
          key={index}
          className={
            "product-slider-control-button" + (index == image ? " active" : "")
          }
          onClick={() => {
            setImage(index);
            setType(index);
          }}
          style={{ backgroundImage: "url(" + Env.PUBLIC_URL + item + ")" }}
        ></button>
      );
    }
  );

  var giftsList = gifts.map((item, index) => {
    if (index < 5)
      return (
        <UiGiftCard
          key={index}
          item={item}
          selected={props.checkInUserBasket(item.id)}
          onClick={() => {
            navigate(`/product/${item.title_url}`);
          }}
        />
      );
  });

  var flowersList = flowers.map((item, index) => {
    if (index < 5)
      return (
        <UiGiftCard
          key={index}
          item={item}
          selected={props.checkInUserBasket(item.id)}
          onClick={() => {
            navigate(`/product/${item.title_url}`);
          }}
        />
      );
  });

  var addList = additional.map((item, index) => {
    if (item.price > 0) {
      return (
        <UiCheck
          key={index}
          label={item.title}
          labelMark={`+ ${item.price} ₽`}
          value={inArray(addBasket, item)}
          onChange={() => {
            if (!inArray(addBasket, item)) {
              if (props.checkInUserBasket(item.id)) {
                props.changeUserBasket(item.id, 0);
              } else {
                let _item = item;
                _item.count = 1;
                props.addUserBasket(_item);
              }
            } else {
              if (props.checkInUserBasket(item.id)) {
                props.changeUserBasket(item.id, 0);
              }
            }

            setAddBasket(unCheckInArray(addBasket, item));
          }}
        />
      );
    }
  });

  var tagsList = (item ? item.tags : "")?.split(",").map((item, index) => {
    return (
      <div key={index} className="product-slider-image-status-item">
        <span>{item}</span>
      </div>
    );
  });

  let sale_info_list = getProductSale(item, compositionList).map(
    (item, index) => {  
      return (
        <div className="product-about-sale" key={index}>
          <p>{`${item.custom_price.includes("%")  ? `Скидка` : 'Цена' } при покупке от ${item.count_lower} шт:`}</p>
          <p>
          {item.custom_price.includes("%")  ? <strong>{`${item.custom_price}`}</strong> : <strong>{`${item.custom_price} ₽`}</strong> }
          </p>
        </div>
      );
    }
  );

  return (
    <PageContainer
      page="product"
      pageTitle={` ${
        item ? item.title : ""
      } купить цветы в Сургуте с доставкой | БукетBery `}
      pageDescription={`${
        item ? item.title : ""
      } купить в студии декора и флористики ❀ БукетBery ❀ с доставкой по Сургуте ➤ Доступные цены и огромный каталог букетов`}
    >
      <Container>
        <div className="product-info">
          {/**
           * image slider
           */}
          <div className="product-slider">
            <div className="product-slider-image-wrap">
              {loader ? (
                <Skeleton className="product-slider-image" />
              ) : item ? (
                <div
                  className="product-slider-image"
                  style={{
                    backgroundImage:
                      "url(" +
                      (item.images
                        ? `${Env.PUBLIC_URL}${
                            convertImages(item.images)[image]
                          }`
                        : `${Env.PUBLIC_URL}${
                            convertImages(item.imgs)[image]
                          }`) +
                      ")",
                  }}
                >
                  <div className="product-slider-image-status">
                    {item.tags && tagsList}
                  </div>
                </div>
              ) : null}
              <div className="product-slider-control">
                {loader ? (
                  <Skeleton className="product-slider-control-button" />
                ) : (
                  imageButtons.length > 1 && imageButtons
                )}
              </div>
            </div>
          </div>

          {/**
           * product about info
           */}
          <div className="product-about">
            {productTypes.length > 0 && (
              <div className="product-type">
                <UiCheckList
                  checkList={productTypes}
                  label="Выберите тип"
                  tabs={true}
                  onChange={(val) => {
                    setItem({
                      ...item,
                      image: convertImages(productTypes[val].image)[0],
                      images: productTypes[val].image,
                      custom: productTypes[val].id,
                      price: productTypes[val].price,
                      custom_price: productTypes[val].price,
                      sale: productTypes[val].sale,
                      sale_count: productTypes[val].sale_count,
                      custom_composition: productTypes[val].custom_composition,
                      custom_id: productTypes[val].storage_id,

                      composition: [],
                      product_title: productTypes[val].product_title,
                      title: productTypes[val].option,
                      text: productTypes[val].text,
                    });
                  }}
                  selected={type}
                />
              </div>
            )}
            <h3>
              {loader ? <Skeleton width={200} /> : item ? item.title : null}
            </h3>
            <div className="product-about-price">
              {loader ? (
                <Skeleton width={100} />
              ) : (
                <p>
                  {item && getProductPrice(item, selectedNum, compositionList)}
                  <span>&nbsp;₽</span>
                </p>
              )}
            </div>
          
            {item ? (
              <div className="product-about-sale">
                <p>
                  {loader ? (
                    <Skeleton width={250} />
                  ) : item.sale > 0 ? (
                    `Цена при покупке от ${item.sale_count} шт:`
                  ) : (
                    ""
                  )}
                </p>
                <p>
                  <strong>
                    {loader ? (
                      <Skeleton width={30} />
                    ) : item.sale > 0 ? (
                      `${item.sale} ₽`
                    ) : (
                      ""
                    )}
                  </strong>
                </p>
              </div>
            ) : null}
            <div className="product-add">
              <UiBarNum
                loader={loader}
                num={selectedNum}
                onMinus={() =>
                  selectedNum > 1 ? setSelectedNum(selectedNum - 1) : 1
                }
                onPlus={() => setSelectedNum(selectedNum + 1)}
              />
              {loader ? (
                <Skeleton width={273.36} height={52} />
              ) : props.checkInUserBasket(item ? item.id : null) ? (
                <UiBtnColor
                  color="accent"
                  small
                  text={"Заказать"}
                  onClick={() => {
                    navigate("/order");
                  }}
                />
              ) : (
                <UiBtnColor
                  color="dark"
                  outline={true}
                  small
                  text={
                    item.status == 1 ? "Хочу похожий" : "Добавить в корзину"
                  }
                  onClick={() => {
                    item.count = selectedNum;
                    props.addUserBasket(item);

                    showNotify(`Добавлено: ${item.title}`);

                    /**
                     * Добавить ленту по умолчанию к цветам
                  
                    if (item.category == 4) {
                      let _item = additional[1];
                      if (!props.checkInUserBasket(_item.id)) {
                        _item.count = 1;
                        props.addUserBasket(_item);
                        setAddBasket(unCheckInArray(addBasket, _item));
                      }
                    }
                   */
                  }}
                />
              )}
            </div>

            <div className="product-description">
              <h4>
                {loader ? (
                  <Skeleton count={2} width={"100%"} />
                ) : item ? (
                  item.status == 1 ? (
                    "*ДАННАЯ ПОЗИЦИЯ ПОД ЗАКАЗ, ВОЗМОЖНЫ ИЗМЕНЕНИЯ СОСТАВА"
                  ) : (
                    ""
                  )
                ) : null}
              </h4>
            </div>

            <>{item && sale_info_list}</>

            <div className="product-description">
              <h4>ОПИСАНИЕ</h4>
              <p>
                {loader ? (
                  <Skeleton count={2} width={"100%"} />
                ) : item ? (
                  item.text
                ) : null}
              </p>
            </div>

            {loader ? (
              <Skeleton
                className="product-add-skeleton"
                count={3}
                width={200}
                height={24}
              />
            ) : (
              <div className="product-add-more">
                {item.category == 4 ? <h3>Добавить к цветам</h3> : null}
                {item.category == 4 ? addList : null}

                {item.category != 5 ? (
                  <h3>К цветам подходит</h3>
                ) : (
                  <h3>К подаркам подходит</h3>
                )}
                {item.category != 5 ? (
                  <div className="product-add-gifts">{giftsList}</div>
                ) : (
                  <div className="product-add-gifts">{flowersList}</div>
                )}
              </div>
            )}
            {loader ? (
              <Skeleton
                className="product-add-skeleton"
                count={3}
                width={"100%"}
                height={20}
              />
            ) : (
              item.category != 5 && (
                <div className="product-description">
                  <h4>НАЛИЧИЕ И ВНЕШНИЙ ВИД</h4>
                  {subCat?.description_availability ? (
                    <p>{subCat?.description_availability}</p>
                  ) : item.category != 1 ? (
                    <p>
                      Каждая изготавливаемая цветочная композиция является
                      уникальной. Состав букета и упаковка могут быть изменены в
                      зависимости от ассортимента на момент заказа.
                      <br /> <br />
                      Мы гарантируем сохранить цветовую гамму, стиль букета и
                      его объем. Значительные замены цветов - оговариваются
                      дополнительно.
                    </p>
                  ) : (
                    <p>
                      Данный букет уже собран и готов к доставке. Если вы
                      желаете собрать подобный букет на свой бюджет, обратитесь
                      к нашим менеджерам по телефону.
                    </p>
                  )}

                  <h4>
                    {item.tips_type == 0
                      ? "Уход за букетом"
                      : "Уход за композицией"}
                  </h4>
                  <UiCareCard type={item.tips_type} />

                  {/*    
                  <h4>КОМПЛЕКТАЦИЯ</h4>
                  <p>
                    В комплекте с букетом мы прилагаем инструкцию по уходу за
                    букетом и средство для продления жизни цветов.
                  </p>
                  */}
                </div>
              )
            )}
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, category }) => ({ storage, login, category }),
  {
    getCompositionByStorage: getCompositionByStorageAction,
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,
    getCompositions: getCompositionsAction,
    getSubCategory: getSubCategoryAction,
    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    changeUserBasket: changeUserBasketAction,
  }
)(ProductPage);
