export default {
  VOLUME_ARRAY: [
    { value: 0, text: "до 1,5 кубов" },
    { value: 1, text: "от 1,5 до 2,5 кубов" },
    { value: 2, text: "от 7 до 10 кубов" },
    { value: 3, text: "от 10 до 13 кубов" },
    { value: 4, text: "от 13 до 16 кубов" },
    { value: 5, text: "от 16 до 20 кубов" },
    { value: 6, text: "от 20 до 40  кубов" },
    { value: 7, text: "свыше 40 кубов" },
  ],
  TRASH_ARRAY: [
    { value: 0, text: "до 1 тонны " },
    { value: 1, text: "от 1 до 1,5 тонн" },
    { value: 2, text: "от 1,5 до 2 тонн" },
    { value: 3, text: "свыше 2 тонн" },
  ],
  TAK_ARRAY: [
    { value: 0, text: "1 этаж" },
    { value: 1, text: "Такелаж сейфа до 150 кг" },
    { value: 2, text: "Такелаж сейфа до 250 кг" },
    { value: 3, text: "Такелаж сейфа свыше 250 кг" },
    { value: 4, text: "Такелаж сейфа свыше 250 кг" },
  ],
  UNBUILD_ARRAY: [
    { value: 0, text: "до 1 метра" },
    { value: 1, text: "от 1 до 2,5 метра" },
    { value: 2, text: "от 2,5 и выше" },
  ],
  PACK_ARRAY: [
    { value: 0, text: "Стретч пленка" },
    { value: 1, text: "Пупырчатая пленка" },
  ],
  MOVING_TYPE: [
    { id: 0, text: "Офисный" },
    { id: 1, text: "Домашний (коробки)" },
    { id: 2, text: "Домашний (габарит)" },
    { id: 3, text: "Стройматериалы" },
    { id: 4, text: "Вывоз мусора" },
  ],
  PRR_TYPE: [
    { id: 0, text: "Без ПРР" },
    { id: 1, text: "С ПРР" },
  ],
  LIFT_TYPES: [
    { id: 0, text: "Нет" },
    { id: 1, text: "Обычный" },
    { id: 2, text: "Грузовой" },
  ],

  ORDER_TYPES_NAMES: [
    "Новый заказ",
    "Букет готов",
    "Доставляется",
    "Отменен",
    "Выполнен",
  ],

  ORDER_PAYMENTS_TYPES_NAMES: [
    "Оплата онлайн",
    "",
    "",
    "Выездной терминал",
    "Оплата курьеру",
    "",
    "",
  ],
 
  ORDER_DELIVERY_TYPES_NAMES: [
    "Доставка",
    "Самовывоз",
  ],
  ORDER_IS_PAY: [
    "Ожидает",
    "Оплачен",
  ],

  
  ORDER_SELF_DELIVERY: [
    "Комсомольский проспект, 11",
    "​Семёна Билецкого, 1",
  ],

  ORDER_SELF_DELIVERY_NAMES: [
    {option : "Комсомольский проспект, 11"},
    {option : "​Семёна Билецкого, 1"},
  ],

 

  ORDER_TIME_TYPE: [
    null, 
    "8:00 - 9:00",
    "9:00 - 10:00",
    "10:00 - 11:00",
    "11:00 - 12:00",
    "12:00 - 13:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
    "18:00 - 19:00",
    "19:00 - 20:00", 
    "20:00 - 21:00", 
  ],

};