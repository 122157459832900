import React from "react";
import { Routes, Navigate, useLocation, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";

import HomePage from "../pages/catalog/HomePage";
import CatalogPage from "../pages/catalog/CatalogPage";
import FlowersPage from "../pages/catalog/FlowersPage";
import GiftsPage from "../pages/catalog/GiftsPage";
import ProductPage from "../pages/product/ProductPage";

import LoginPage from "../pages/login/LoginPage";
import ProfilePage from "../pages/profile/ProfilePage";

import OrderPage from "../pages/order/OrderPage";
import UserOrderPage from "../pages/order/UserOrderPage";
import UserPayPage from "../pages/order/UserPayPage";
import OrderSuccessPage from "../pages/order/OrderSuccessPage";

import DeliveryPage from "../pages/static/DeliveryPage";
import ContactsPage from "../pages/static/ContactsPage";
import VacancyPage from "../pages/static/VacancyPage";
import CorpPage from "../pages/static/CorpPage";
import PolicyPage from "../pages/static/PolicyPage";

export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    );
  } else return null;
};

function AppNavigator() {
  return (
    <>
      <RemoveTrailingSlash />
      <Routes>
        <Route sensitive={false} path="/" element={<HomePage />} />

        <Route sensitive={false} path="/catalog" element={<CatalogPage />} />

        <Route sensitive={false} path="/flowers" element={<FlowersPage />} />

        <Route sensitive={false} path="/gifts" element={<GiftsPage />} />

        <Route
          sensitive={false}
          path="/product/:title"
          element={<ProductPage />}
        />

        <Route sensitive={false} path="/login" element={<LoginPage />} />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order"
          element={
            <ProtectedRoute>
              <OrderPage />
            </ProtectedRoute>
          }
        />
        <Route sensitive={false} path="/form/:id" element={<UserOrderPage />} />
        <Route sensitive={false} path="/pay/:id" element={<UserPayPage />} />

        <Route
          sensitive={false}
          path="/order/success"
          element={<OrderSuccessPage />}
        />

        <Route sensitive={false} path="/delivery" element={<DeliveryPage />} />
        <Route sensitive={false} path="/contacts" element={<ContactsPage />} />
        <Route sensitive={false} path="/vacancies" element={<VacancyPage />} />
        <Route sensitive={false} path="/corp" element={<CorpPage />} />
        <Route sensitive={false} path="/policy" element={<PolicyPage />} />

        <Route
          sensitive={false}
          path="*"
          element={<CatalogPage />}
          status={404}
        />
      </Routes>
    </>
  );
}

export default AppNavigator;
