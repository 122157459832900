import React from "react";
import UiLoaderIcon from "../icon/UiLoaderIcon";

import UiModal from "./UiModal";

import "./UiModal.scss";

const UiLoader = (props) => {
  return (
    <UiModal show={props.modalOpen} light={true}>
      <div id="loader" className="loader">
        <UiLoaderIcon type="dual-ball" />
      </div>
    </UiModal>
  );
};

export default UiLoader;
