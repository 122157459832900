import React from "react";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnIcon from "../../components/buttons/UiBtnIcon";
import UiIcon from "../../components/icon/UiIcon";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Static.scss";

function ContactsPage() {
  return (
    <PageContainer
      page="contacts"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/delivery", label: "Контакты" }]}
        pageName="Наши контакты"
      />
      <Container>
        <div className="contacts-wrap">
          <div className="contacts-info-list">
            <h3>Информация о магазинах:</h3>
            <div className="contacts-info">
              <UiIcon icon="pin" fill="pink" size="20" />
              <span>г. Сургут, проспект Комсомольский, 11</span>
            </div>
            <div className="contacts-info">
              <UiIcon icon="pin" fill="pink" size="20" />
              <span>г. Сургут, ​Семёна Билецкого, 1</span>
            </div>
            <a className="contacts-info" href="tel:+79128176377">
              <UiIcon icon="call" fill="pink" size="20" />
              <span>8 (912) 817-63-77</span>
            </a>
            <a className="contacts-info" href="mailto:buketbery@yandex.ru">
              <UiIcon icon="message" fill="pink" size="20" />
              <span>buketbery@yandex.ru</span>
            </a>
            <div className="contacts-info">
              <UiIcon icon="clock" fill="pink" size="18" />
              <span>Время работы: ежедневно с 8:00 до 20:00</span>
            </div>
            <h3>Информация о юридическом лице:</h3>
            <div className="contacts-info">
              <UiIcon icon="user" fill="pink" size="20" />
              <span>ИП Бигун Дмитрий Викторович</span>
            </div>
            <div className="contacts-info">
              <UiIcon icon="clipboard" fill="pink" size="20" />
              <span>ИНН 550622790328</span>
            </div>
            <div className="contacts-info">
              <UiIcon icon="pin" fill="pink" size="20" />
              <span>Юридический адрес: ХМАО-Югра, г. Сургут, наб. И.Кайдалова, 28</span>
            </div>
          </div>
          <div className="contacts-info-list">
            <h3>ПРИСОЕДИНЯЙТЕСЬ К НАМ В СОЦ. СЕТЯХ</h3>
            <div className="contacts-info">
              <UiBtnIcon
                color="gray"
                icon="whatsapp"
                iconSize="24"
                fill="white"
                href="https://wa.me/+79128176377"
                size="36"
                style={{ marginRight: 16 }}
                target="_blank"
              />
              <UiBtnIcon
                color="gray"
                icon="telegram"
                iconSize="24"
                fill="white"
                href="https://t.me/buketbery_surgut186"
                size="36"
                style={{ marginRight: 16 }}
                target="_blank"
              />
              <UiBtnIcon
                color="gray"
                icon="vk"
                iconSize="24"
                fill="white"
                href="https://vk.com/buketbery_surgut"
                size="36"
                style={{ marginRight: 16 }}
                target="_blank"
              />
              <UiBtnIcon
                color="gray"
                icon="instagram"
                iconSize="24"
                fill="white"
                href="https://instagram.com/buketbery_surgut?igshid=MzRlODBiNWFlZA=="
                size="36"
                target="_blank"
              />
            </div>
            <h3>Наши магазины</h3>
            <div className="contacts-map">
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae03367f127d25eff047c53a3f06c44a4f49e0e6864c2fc37b653f63cf4c2b62a&amp;source=constructor"
                width="100%"
                height="400"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default ContactsPage;
