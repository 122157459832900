import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./UiModal.scss";

const UiModal = ({ children, light, show }) => {
  const [checkVerticalScroll, setCheckVerticalScroll] = useState(false);
  const [topScrollConst, setTopScrollConst] = useState(0);

  useEffect(() => {
    if (document.getElementById("modal-inner")) {
      let windowHeight = window.innerHeight;
      let modalHeight = document.getElementById("modal-inner").offsetHeight;

      setTopScrollConst(modalHeight + 100 > windowHeight);
    }
  }, []);

  useEffect(() => {
    if (show) {
      _openModal();
    } else {
      _closeModal();
    }
  }, [show]);

  const _openModal = () => {
    if (document.getElementById("scroll")) {
      let top =
        window.pageYOffset || document.getElementById("scroll").scrollTop;
      setCheckVerticalScroll(top);

      let element = document.getElementById("scroll");
      element.classList.toggle("scroll-lock");
      ReactDOM.findDOMNode(element).style.top = -top + "px";
    }
  };

  const _closeModal = () => {
    let element = document.getElementById("scroll");
    if (element) {
      element.classList.toggle("scroll-lock");

      window.scrollTo(0, topScrollConst);
    }
  };

  if (show) {
    return (
      <div
        className={
          "modal" +
          (light ? " light" : "") + 
          (show ? " show" : " hide") +
          (checkVerticalScroll ? " scroll" : " center")
        }
      >
        <div className="modal-inner" id="modal-inner">
          {children}
        </div>
      </div>
    );
  }
};

export default UiModal;
