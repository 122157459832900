import ENV from './Env.js';
import ApiConstant from '../constants/ApiConstan.js';



export const storeOrder = (_data) => {
    console.log(_data);
    
    return fetch(`${ENV.API_URL}/deal/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
 
export const updateUserOrder = (_id,_data) => {
    return fetch(`${ENV.API_URL}/deal/user/update/${_id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}


export const showDeal = (_id) => {
    return fetch(`${ENV.API_URL}/deal/show/id/${_id}`, {
        method: 'GET',
        headers: ENV.getHeaders(), 
    }).then(function (response) {
        return response.json();
    });

}


export const getMyOrder = (_data) => {
    return fetch(`${ENV.API_URL}/deal/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const getOrdersByUser = (_data) => {
    return fetch(`${ENV.API_URL}/deal/show/user`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const addOrderSpecialComposition = (_data) => {
    return fetch(`${ENV.API_URL}/composition/store/special`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });
}

export const getOrderComposition = (_id) => {
    return fetch(`${ENV.API_URL}/deal/composition/show/${_id}`, {
        method: 'GET',
        headers: ENV.getHeaders(), 
    }).then(function (response) {
        return response.json();
    });

}

export const getPayLink = (_data) => {
    return fetch(`${ENV.API_URL}/deal/payment`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}


