import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SvgLogo } from "../../assets/images/logo/logo.svg";

import UiBtnColor from "../buttons/UiBtnColor";
import UiIcon from "../icon/UiIcon";
import UiSliderPromo from "../sliders/UiSliderPromo";

import "./UiHomeTitle.scss";

export default function UiHomeTitle(props) {
  let navigate = useNavigate();

  return (
    <div className="home-title">
      <div className="home-title-wrap">
        <div>
          <h3>
            Доставка цветов <br />
            <span>в Сургуте</span>
          </h3>
          <p>Действуют постоянные акции на большие букеты</p>
          <a href="tel:89128176377">
            <UiIcon icon="call" fill="pink" size={24} />{" "}
            <span>8 (912) 817-63-77</span>
          </a>
          <a href="tel:83462676377">
            <UiIcon icon="call" fill="pink" size={24} />{" "}
            <span>8 (3462) 676-377</span>
          </a>
        </div>
        <UiBtnColor
          color="accent"
          //outline={true}
          text="Выбрать букет"
          onClick={() => {
            navigate("/showcase");
          }}
        />
      </div>
      {/**
       * slider promo
       */}
      <UiSliderPromo promoList={props.promoList} />
    </div>
  );
}
