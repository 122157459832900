import React from "react";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnIcon from "../../components/buttons/UiBtnIcon";
import UiIcon from "../../components/icon/UiIcon";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Static.scss";

function CorpPage() {
  return (
    <PageContainer
      page="corp"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/corp", label: "Корпоративным клиентам" }]}
        pageName="Корпоративным клиентам"
      />
      <Container>
        <div className="corp-wrap">
          
        </div>
      </Container>
    </PageContainer>
  );
}

export default CorpPage;
