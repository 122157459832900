import {
  storeData,
  retrieveData,
  safelyParseJSON,
} from "../../services/Storage";
import {
  storeOrder,
  showDeal,
  updateUserOrder,
  getOrdersByUser,
  getPayLink,
  addOrderSpecialComposition,
  getOrderComposition,
} from "../../services/Order";
import { getAdressAutocompleatKladr } from "../../services/Adress";
import { inArray } from "../../utilities/array";

import { addFile } from "../../services/Storage";
import Env from "../../services/Env";

const moduleName = "order";
const SET_ORDER = `${moduleName}/SET_ORDER`;
const SET_USER_ORDERS = `${moduleName}/SET_USER_ORDERS`;
const SET_ADDRESS = `${moduleName}/SET_ADDRESS`;

const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const REGISTER = `${moduleName}/REGISTER`;

const defaultState = {
  order: {
    //step1
    address_a: "",
    address_b: "",
    elevator_type_a: 0,
    elevator_type_b: 0,
    address_a_city: null,
    address_b_city: null,

    floor_a: "",
    floor_b: "",
    date: new Date(),
    time: new Date(),
    date_dmy: null,
    time_hm: null,
    //step2
    moving_types: 0,
    prr_types: 0,
    volume_car: 0,
    movers: 0,
    additional_work: [],
    tak_value: 0,
    unbuild_value: 0,
    pack_value: 0,
    trash_value: 0,
    photos: [],
    text: "",

    distance: 0,
  },
  ordersList: [],
  addressList: [],
  isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_ORDER:
      return { ...state, order: payload };
    case SET_USER_ORDERS:
      return { ...state, ordersList: payload };
    case SET_ADDRESS:
      return { ...state, addressList: payload };

    case SET_API_REQUEST:
      return { ...state, isFetching: payload };

    default:
      return state;
  }
};

export const findAddress = (_line) => async (dispatch, getState) => {
  try {
    return getAdressAutocompleatKladr(_line).then((res) => {
      //console.log(res.suggestions);

      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      dispatch({
        type: SET_ADDRESS,
        payload: res.suggestions,
      });

      return res.suggestions;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getMyOrders = () => async (dispatch, getState) => {
  try {
    let _obj = {
      api_token: getState().login.user.api_token,
      user_id: getState().login.user.id,
    };

    return getOrdersByUser(_obj).then((res) => {
      console.log(res);
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      dispatch({
        type: SET_USER_ORDERS,
        payload: res.response,
      });

      return res.suggestions;
    });
  } catch (error) {
    console.error(error);
  }
};

export const setOrder = (_val) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_ORDER,
      payload: _val,
    });
    storeData("SET_ORDER", _val);
  } catch (error) {
    console.error(error);
  }
};

export const getOrderId = (_id) => async (dispatch, getState) => {
  try {
    return showDeal(_id).then((res) => {
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      dispatch({
        type: SET_ORDER,
        payload: res.response,
      });

      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateOrder = (_id, _data) => async (dispatch, getState) => {
  try {
    return updateUserOrder(_id, _data).then((res) => {
      console.log(res);
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });

      return res;
    });
  } catch (error) {
    console.error(error);
  }
};

export const addAlternativeToOrder =
  (_id, _basket, _api_token) => async (dispatch, getState) => {
    try {
      return await Promise.all(
        _basket.map(async (element) => {
          element["order_id"] = _id;
          element["api_token"] = _api_token;
          return addOrderSpecialComposition(element).then((res) => {
            return res;
          });
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

export const addOrder =
  (_order, _basket, _user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });
      let ids = [];
      let additional = [];
      _basket.map((item) => {
        if (item.custom) {
          additional.push({
            order_id: null,
            storage_id: null,

            custom_id: item.storage_id,
            custom_composition: item.custom_composition,

            custom_title: item.title,
            custom_count: item.count,
            custom_price: item.price,
          });
        } else {
          if (item.count > 1) {
            for (let i = 0; i < item.count; i++) {
              ids.push(item.id);
            }
          } else {
            ids.push(item.id);
          }
        }
      });

      let order = JSON.parse(JSON.stringify(_order));
      order["user_id"] = _user.id;
      order["api_token"] = _user.api_token;
      order["type"] = 0;
      order["status"] = 0;
      order["order_type"] = 0;
      order["order_name"] = "";
      order["elevator_type_a"] = 0;
      order["floor_a"] = "";
      order["photos"] = "";
      order["composition"] = ids.join(",");
      order["composition_list"] = _basket;

      return storeOrder(order)
        .then((res) => {
          dispatch({
            type: SET_API_REQUEST,
            payload: false,
          });
          if (res.status == 200) {
            if (additional.length > 0) {
              dispatch(
                addAlternativeToOrder(
                  res.response.id,
                  additional,
                  _user.api_token
                )
              );
            }
          }

          return res.response;
        })
        .catch((err) => {
          return err;
        });
    } catch (error) {
      console.error(error);
    }
  };

export const addPhoto = (_uri) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return addFile(_uri, 0).then((res) => {
      console.log(res);
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });

      return res;
    });
  } catch (error) {
    console.error(error);
  }
};

export const makePayment =
  (_order ) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_API_REQUEST,
        payload: true,
      });

     return  getOrderComposition(_order.id).then((orderRes) => {
        if (orderRes.response) {
          let receiptList = [];
          orderRes.response.map((item) => {
            receiptList.push({
              description: item.custom_title,
              amount: {
                value:  (item.custom_price.toFixed(2)),  
                currency: "RUB"
              },
              quantity: item.custom_count,
              vat_code: 1,
            });
          });
          if(_order.price_delivery > 0){
            receiptList.push({
                description: "Доставка",
                amount: {
                  value:  (_order.price_delivery.toFixed(2)),  
                  currency: "RUB"
                },
                quantity: 1,
                vat_code: 1,
              });
          }

          return getPayLink({
            phone:  _order.phone,
            items: receiptList,
            amount: _order.price.toFixed(2),
            orderNumber: _order.id,
            returnUrl: `${Env.HOST_URL}order/success`,
            Description: `Онлайн оплата заказа #${_order.id}`,
          }).then((res) => {
            console.log(res);
            dispatch({
              type: SET_API_REQUEST,
              payload: false,
            });

            return res;
          });
        } else {
          return {
            errorCode: 0,
            errorMessage: "Ошибка состава",
          };
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
