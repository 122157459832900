import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBasketCard from "../../components/cards/UiBasketCard";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheckList from "../../components/forms/UiCheckList";
import UiCheck from "../../components/forms/UiCheck";
import UiLoader from "../../components/modals/UiLoader";
import UiPageTitle from "../../components/header/UiPageTitle";
import UiSelectDate from "../../components/forms/UiSelectDate";
import UiSelectOption from "../../components/forms/UiSelectOption";
import UiTextArea from "../../components/forms/UiTextArea";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextInputMasked from "../../components/forms/UiTextInputMasked";
import UiTextInputSV from "../../components/forms/UiTextInputSV";

import { formatDateYMD, excludeOldTime } from "../../utilities/date";
import showNotifyMsg from "../../components/notify/UiNotify";

import OrderConstant from "../../constants/OrderConstant";

import {
  getStorageListByIdArray as getStorageListByIdArrayAction,
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
  getStoredUserBasket as getStoredUserBasketAction,
  changeUserBasket as changeUserBasketAction,
} from "../../redux/modules/login";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  getSettingDelivery as getSettingDeliveryAction,
} from "../../redux/modules/setting";
import {
  updateOrder as updateOrderAction,
  getOrderId as getOrderIdAction,
} from "../../redux/modules/order";

import Env from "../../services/Env";

import { getTotalPrice } from "../../utilities/order";
import {
  toArrayList,
  toOptionsList,
  inArrayValueByKey,
} from "../../utilities/array";

import "./Order.scss";

function UserOrderPage(props) {
  let { id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  const minDeliveryPrice = 300;

  const [order, setOrder] = useState(null);
  const [settings, setSettings] = useState([]);

  const [discount, setDiscount] = useState(0);
  const [deliveryPrice, setDP] = useState(minDeliveryPrice);
  const [orderPrice, setPrice] = useState(0);
  const [freeDeliveryPrice, setFDP] = useState(3500);
  const [priceInZoneLess, setPriceInZoneLess] = useState(250);
  const [priceInZoneMore, setPriceInZoneMore] = useState(0);
  const [basketList, setBasket] = useState([]);

  const [minimalDeliveryPrice, setMinimalDeliveryPrice] =
    useState(minDeliveryPrice);
  const [deliveryInterval, setDeliveryInterval] = useState(
    excludeOldTime(new Date(), OrderConstant.ORDER_TIME_TYPE)
  );

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [addressHome, setAddressHome] = useState("");
  const [addressApart, setAddressApart] = useState("");
  const [addressFloor, setAddressFloor] = useState("");

  const [addressPod, setAddressPod] = useState("");
  const [addressHCode, setAddressHCode] = useState("");

  const [hasPostcard, setHasPostcard] = useState(false);

  const [comment, setComment] = useState("");
  const [commentFl, setCommentFl] = useState("");
  const [commentCurr, setCommentCurr] = useState("");

  const [movingType, setMovingType] = useState(0);
  const [selfDeliveryPoint, setSelfDeliveryPoint] = useState(0);

  const [distance, setDistance] = useState(null);
  const [paymentType, setPaymentType] = useState(0);

  const [deliverySurprise, setDS] = useState(true);
  const [call, setCall] = useState(false);

  const [aeroWO, setAeroWO] = useState(false);
  const [aero, setAero] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.getOrderId(id);
  }, [location]);

  useEffect(() => {
    if (props.order.order && props.order.order.id) {
      let _order = props.order.order;
      setOrder(_order);
      setPrice(_order.price);
      setDiscount(_order.price_discount);
      setDP(_order.price_delivery);
      setPhone(_order.user_phone ? _order.user_phone : _order.phone);
      setDate(new Date(_order.date));
      setTime(_order.time);
      setAddress(_order.address_a);
      setComment(_order.postcard);
      setCommentFl(_order.comment);
      setCommentCurr(_order.text);

      setName(_order.user_name ? _order.user_name : "");
      if (_order.composition) {
        props.getStorageListByIdArray(_order.composition).then((data) => {
          setBasket(data);
        });
      }
    }
  }, [props.order.order]);

  useEffect(() => {
    props.getStoredUserBasket();
    props.getSettingList();
  }, []);

  useEffect(() => {
    if (props.setting.list) {
      if (props.setting.list.length > 0) {
        if (settings.length == 0) setSettings(props.setting.list);
        setFDP(parseInt(props.getSettingValue("sum_busket_free_delivery")));

        setMinimalDeliveryPrice(
          props.getSettingDelivery(settings, 0) > 0
            ? props.getSettingDelivery(settings, 0)
            : minDeliveryPrice
        );
      }
    }
  }, [props.setting.list]);

  useEffect(() => {
    console.log(movingType);
    if (movingType == 1) {
      setDP(0);
    } else {
      if (distance == null) {
        console.log(getTotalPrice(basketList, 0, discount, false));
        let dp =
          getTotalPrice(basketList, 0, discount, false) >= freeDeliveryPrice
            ? 0
            : minimalDeliveryPrice;
        setDP(dp);
      } else {
        let dp = props.getSettingDelivery(settings, distance);
        setDP(dp);
      }
    }
  }, [distance, discount, settings, basketList, movingType]);

  useEffect(() => {
    //todo: check this
    if (movingType == 0) {
      if (deliveryPrice == 0) {
        if (distance == 0 || distance == null)
          setDP(
            getTotalPrice(basketList, 0, discount, false) >= freeDeliveryPrice
              ? 0
              : minimalDeliveryPrice
          );
        else if (distance > 0) setDP(minimalDeliveryPrice);
      }
    }
  }, [deliveryPrice]);

  function checkForm() {
    return (
      name.length > 0 &&
      phone.length > 7 &&
      (movingType != 0 ||
        (time != "" && (address?.length > 0 || aero || aeroWO))) &&
      address != null
    );
  }

  function edit() {
    setLoading(true);
    let _address = "";
    _address =
      _address +
      address +
      (addressHome.length > 0 ? " ,дом " + addressHome : "") +
      (addressApart.length > 0 ? " ,кв " + addressApart : "") +
      (addressFloor.length > 0 ? " ,этаж " + addressFloor : "") +
      (addressPod.length > 0 ? " ,подъезд " + addressPod : "") +
      (addressHCode.length > 0 ? " ,домофон " + addressHCode : "");

      let type = OrderConstant.ORDER_SELF_DELIVERY[selfDeliveryPoint];
      
    props
      .updateOrder(order.id, {
        address_a: movingType == 0 ? _address : type,
        shipment_point: selfDeliveryPoint + 1,
        date: date,
        time: time,
        moving_types: movingType,
        payment_type: paymentType,
        user_phone: phone,
        user_name: name,
        postcard: comment,
        text: commentCurr,
        comment: commentFl,
        user_call: call ? 1 : 0,
      })
      .then((res) => {
        if (res) {
          navigate("/order/success");
        }
        setLoading(false);
      });
  }

  let basket = basketList.map((item, index) => {
    return (
      <UiBasketCard
        key={index}
        image={Env.PUBLIC_URL + item.images[0].name}
        title={item.title}
        text={item.text}
        price={item.price}
        num={1}
      />
    );
  });

  return (
    <PageContainer
      page="order"
      pageTitle="buketbery86 | букетбери86- доставка цветов в Сургуте"
      alertOpen={true}
      alertTitle="Заголовок"
      alertText="Текст сообщения"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/order", label: "Корзина" }]}
        pageName="Оформление заказа"
      />
      <Container>
        <div className="order-wrap">
          <div className="basket-list">
            <h3>Состав заказа</h3>
            {basket}

            <p>
              Время доставки не может быть раннее, чем через 2 часа после
              оформления заказа. Доставка осуществляется круглосуточно на
              территории г. Сургут.
            </p>

            {basket.length > 0 ? (
              <div className="basket-list-coast">
                <p>Стоимость доставки</p>
                <p>
                  <strong>
                    {deliveryPrice > 0 ? deliveryPrice : "бесплатно"}
                  </strong>
                </p>
              </div>
            ) : null}

            <div className="basket-list-coast">
              <p>Ваша скидка на заказ составляет</p>
              <p>
                <strong>{discount}%</strong>
              </p>
            </div>

            <div className="basket-list-coast">
              <p>Общая стоимость заказа</p>
              <p>
                <strong>{orderPrice} ₽</strong>
              </p>
            </div>
          </div>
          <div className="order-info">
            <h3>Оформить заказ</h3>
            {order ? (
              <div className="order-info-input">
                <UiTextInput
                  label="Ваше имя"
                  value={name}
                  onChange={(val) => {
                    setName(val);
                  }}
                />
                <UiTextInputMasked
                  label="Ваш номер телефона"
                  value={phone}
                  onChange={(val) => {
                    setPhone(val);
                  }}
                />

                <UiSelectOption
                  label="Тип доставка\самовывоз"
                  value={movingType}
                  optionList={toArrayList(
                    OrderConstant.ORDER_DELIVERY_TYPES_NAMES
                  )}
                  onChange={(e) => {
                    setMovingType(e);
                  }}
                />
                {movingType == 0 ? (
                  <div className="order-info-input-wrap">
                    <UiCheck
                      label="Доставка-сюрприз"
                      value={deliverySurprise}
                      onChange={() => {
                        setDS(!deliverySurprise);
                        setCall(false);
                      }}
                    />
                    <UiCheck
                      label="Предварительный звонок"
                      value={call}
                      onChange={() => {
                        setDS(false);
                        setCall(!call);
                      }}
                    />
                    <small>
                      в указанное вами время получатель должен быть дома
                    </small>

                    <UiSelectDate
                      label="Дата доставки"
                      value={date}
                      onChange={(e) => {
                        setDate(e);
                      }}
                    />

                    {deliveryInterval.length > 0 ? (
                      <UiSelectOption
                        label="Время доставки"
                        optionList={toArrayList(deliveryInterval)}
                        onChange={(e) => {
                          setTime(deliveryInterval[e]);
                        }}
                      />
                    ) : (
                      <small>в данный день нет доставки</small>
                    )}

                    <UiTextInputSV
                      label="Адрес доставки"
                      overflow
                      city={"Сургут"}
                      value={address}
                      callBack={(line, distance) => {
                        console.log("res", address, line, distance);
                        setAddress(line);
                        setDistance(distance);
                      }}
                    />
                  </div>
                ) : (
                  <div className="order-info-input-wrap">
                    <UiCheckList
                      colored={true}
                      checkList={OrderConstant.ORDER_SELF_DELIVERY_NAMES}
                      label="Адрес самовывоза:"
                      onChange={(val) => {
                        setSelfDeliveryPoint(val);
                      }}
                      selected={selfDeliveryPoint}
                    />
                  </div>
                )}

                <div
                  id="ymap"
                  style={{ width: "500px", height: "500px", display: "none" }}
                ></div>

                {movingType == 0 && (
                  <UiTextInput
                    label="Дом"
                    onChange={(val) => {
                      setAddressHome(val);
                    }}
                  />
                )}

                {movingType == 0 && (
                  <UiTextInput
                    label="Квартира"
                    onChange={(val) => {
                      setAddressApart(val);
                    }}
                  />
                )}

                {movingType == 0 && (
                  <UiTextInput
                    label="Подъезд"
                    onChange={(val) => {
                      setAddressPod(val);
                    }}
                  />
                )}

                {movingType == 0 && (
                  <UiTextInput
                    label="Этаж"
                    onChange={(val) => {
                      setAddressFloor(val);
                    }}
                  />
                )}
                {movingType == 0 && (
                  <UiTextInput
                    label="Домофон"
                    onChange={(val) => {
                      setAddressHCode(val);
                    }}
                  />
                )}
                {hasPostcard && (
                  <UiTextArea
                    label="Открытка"
                    onChange={(val) => {
                      setComment(val);
                    }}
                  />
                )}

                <UiTextArea
                  label="Комментарий для флориста"
                  onChange={(val) => {
                    setCommentFl(val);
                  }}
                />

                {movingType == 0 && (
                  <UiTextArea
                    label="Комментарий для курьера"
                    onChange={(val) => {
                      setCommentCurr(val);
                    }}
                  />
                )}

                <UiBtnColor
                  color="accent"
                  disabled={!checkForm()}
                  onClick={() => edit()}
                  text="Сохранить"
                />
              </div>
            ) : (
              <div className="order-info-input">
                <h3> Заказ №${id} не найден :(</h3>
              </div>
            )}
          </div>
        </div>

        <UiLoader modalOpen={loading} />
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, setting, order }) => ({ storage, login, setting, order }),
  {
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,

    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    getStoredUserBasket: getStoredUserBasketAction,
    changeUserBasket: changeUserBasketAction,
    getSettingList: getSettingListAction,
    getSettingValue: getSettingValueAction,
    getSettingDelivery: getSettingDeliveryAction,

    getStorageListByIdArray: getStorageListByIdArrayAction,
    getOrderId: getOrderIdAction,
    updateOrder: updateOrderAction,
  }
)(UserOrderPage);
