import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiHistoryCard.scss";

const UiHistoryCard = (props) => {
  let items = (props.info ? props.info : []).map((item, index) => {
    return `•${item.title} `;
  });

  if (props.skeleton) {
    return <Skeleton count={1} width={"100%"} height={100} />;
  } else {
    return (
      <div className="history-card">
        <div
          className={
            "history-card-status" +
            (props.status === 4 ? " done" : "") +
            (props.status === 0 ? " new" : "") +
            (props.status === 3 ? " cancel" : "")
          }
        >
          <span>{props.statusName}</span>
        </div>
        <div className="history-card-info">
          <p className="history-card-date">{props.date}</p>
          <p className="history-card-price">{props.price}&nbsp;₽</p>
        </div>
        <div className="history-card-content">
          <span>Ваш заказ: {items}</span>
        </div>
      </div>
    );
  }
};
export default UiHistoryCard;
