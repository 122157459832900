import React, { Component } from "react";

import "./UiTextInput.scss";

class UiTextInputSV extends Component {
  state = {
    geocoder: null,
    router: null,
    i: 0, 
    json: {
      type: "FeatureCollection",
      metadata: {
        name: "delivery",
        creator: "Yandex Map Constructor"
      },
      features:  [
        {
          type: "Feature",
          id: 0,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  72.60287118896636,
                  61.08022075865942
                ],
                [
                  72.5960047338882,
                  61.07148947009748
                ],
                [
                  72.61763406738432,
                  61.06050956562679
                ],
                [
                  72.65024972900542,
                  61.070408285368366
                ],
                [
                  72.69917322143705,
                  61.07631276620057
                ],
                [
                  72.74449182495266,
                  61.0729032711683
                ],
                [
                  72.76869607910308,
                  61.076229612158045
                ],
                [
                  72.80646158203272,
                  61.094268903538804
                ],
                [
                  72.78191400512847,
                  61.106067834406865
                ],
                [
                  72.7455217932144,
                  61.11412512950944
                ],
                [
                  72.71273447021633,
                  61.104821677034145
                ],
                [
                  72.69144845947416,
                  61.10174761153995
                ],
                [
                  72.68595529541167,
                  61.11246400010949
                ],
                [
                  72.65282464965968,
                  61.130316552783675
                ],
                [
                  72.63222528442533,
                  61.123840982136315
                ],
                [
                  72.63754678711088,
                  61.11761321621218
                ],
                [
                  72.6238138769546,
                  61.120021431691036
                ],
                [
                  72.62518716797022,
                  61.11420818367142
                ],
                [
                  72.6107676123062,
                  61.112297882354355
                ],
                [
                  72.602656612245,
                  61.11944015509026
                ],
                [
                  72.60662628158663,
                  61.12310409385019
                ],
                [
                  72.6096303556833,
                  61.12816855412192
                ],
                [
                  72.61048866256807,
                  61.13298318737542
                ],
                [
                  72.59830070480442,
                  61.13240215000769
                ],
                [
                  72.59216495988532,
                  61.13496199915944
                ],
                [
                  72.58890339372319,
                  61.13421499609452
                ],
                [
                  72.60186382768315,
                  61.12612132681826
                ],
                [
                  72.59937473771733,
                  61.1235475191814
                ],
                [
                  72.59225079057379,
                  61.12450234332742
                ],
                [
                  72.58847424028076,
                  61.12720060290525
                ],
                [
                  72.57156559465088,
                  61.13151733745869
                ],
                [
                  72.55740353105223,
                  61.13243041701335
                ],
                [
                  72.55328365800536,
                  61.135667485762596
                ],
                [
                  72.5399799012915,
                  61.137493377728994
                ],
                [
                  72.52933689592041,
                  61.139028704918395
                ],
                [
                  72.52350040910403,
                  61.13732739192609
                ],
                [
                  72.5221271180884,
                  61.132803941930966
                ],
                [
                  72.51929470536864,
                  61.13189087319865
                ],
                [
                  72.51637646196045,
                  61.133052956081066
                ],
                [
                  72.51311489579835,
                  61.13218139793997
                ],
                [
                  72.51036831376712,
                  61.13023068039717
                ],
                [
                  72.50092693803465,
                  61.12985712494518
                ],
                [
                  72.49835201738036,
                  61.127159092951956
                ],
                [
                  72.49406048295648,
                  61.12578923439784
                ],
                [
                  72.49088474748287,
                  61.12558167487391
                ],
                [
                  72.48822399614005,
                  61.12815531628779
                ],
                [
                  72.47912594316153,
                  61.13243041699528
                ],
                [
                  72.47509190080314,
                  61.13670493735269
                ],
                [
                  72.4525184297338,
                  61.12915150812036
                ],
                [
                  72.45191761491449,
                  61.12649492653918
                ],
                [
                  72.46307560441642,
                  61.123589033840425
                ],
                [
                  72.46444889543204,
                  61.12263418200891
                ],
                [
                  72.46307560441642,
                  61.121554749432434
                ],
                [
                  72.45886990068108,
                  61.11964489349313
                ],
                [
                  72.46513554093983,
                  61.116945986603824
                ],
                [
                  72.47715183732649,
                  61.092479196653386
                ],
                [
                  72.4543208741917,
                  61.087824618939784
                ],
                [
                  72.42067524430875,
                  61.00296621618021
                ],
                [
                  72.44161793229705,
                  61.00617491716822
                ],
                [
                  72.4517459535373,
                  61.009716611991145
                ],
                [
                  72.46573635575893,
                  61.009841605744036
                ],
                [
                  72.47165867326385,
                  61.002882868954366
                ],
                [
                  72.4878806733859,
                  60.999632156325944
                ],
                [
                  72.49830910203578,
                  61.00652910446308
                ],
                [
                  72.50324436662322,
                  61.01534084212753
                ],
                [
                  72.5127715730441,
                  61.02004776255296
                ],
                [
                  72.5208396577609,
                  61.01908977356553
                ],
                [
                  72.53268429277067,
                  61.00638326310193
                ],
                [
                  72.54298397538786,
                  60.998673549114756
                ],
                [
                  72.55027958390839,
                  61.00025731927519
                ],
                [
                  72.55740353105188,
                  61.00367465862665
                ],
                [
                  72.56684490678428,
                  61.020256017153486
                ],
                [
                  72.56178089616417,
                  61.03882681598734
                ],
                [
                  72.55319782731648,
                  61.04319727911207
                ],
                [
                  72.53894993302936,
                  61.044529109629025
                ],
                [
                  72.52993771073932,
                  61.048940397336644
                ],
                [
                  72.52358623979204,
                  61.058052333871224
                ],
                [
                  72.52495953080766,
                  61.060589893877456
                ],
                [
                  72.53534504411333,
                  61.066288260955275
                ],
                [
                  72.52993771073932,
                  61.07448046092494
                ],
                [
                  72.58409687516797,
                  61.08462419820381
                ],
                [
                  72.59928890702834,
                  61.083876001110625
                ],
                [
                  72.60287118896636,
                  61.08022075865942
                ]
              ]
            ]
          },
          properties: {
            description: "1",
            fill: "#ed4543",
            "fill-opacity": 0.4, 
          }
        },
        {
          type: "Feature",
          id: 1,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.67987466797038,
                  61.60423316072786
                ],
                [
                  73.68742776855628,
                  61.5977325902672
                ],
                [
                  73.69884325012369,
                  61.601821159019146
                ],
                [
                  73.71446443542642,
                  61.59642413395595
                ],
                [
                  73.7309439276139,
                  61.595606320615396
                ],
                [
                  73.74733758911295,
                  61.60120790820933
                ],
                [
                  73.74399019226233,
                  61.60488723041533
                ],
                [
                  73.74158693298503,
                  61.60713548929582
                ],
                [
                  73.73841119751133,
                  61.60938358449127
                ],
                [
                  73.74105049118194,
                  61.611243248571924
                ],
                [
                  73.74111486419825,
                  61.613307140206786
                ],
                [
                  73.73369050964489,
                  61.61338887562301
                ],
                [
                  73.72600866302625,
                  61.6196614251647
                ],
                [
                  73.67987466797038,
                  61.60423316072786
                ]
              ]
            ]
          },
          properties: {
            description: "2",
            fill: "#ed4543", 
          }
        },
        {
          type: "Feature",
          id: 2,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.40724421486144,
                  61.340079885297875
                ],
                [
                  73.40723348602539,
                  61.339471750807064
                ],
                [
                  73.4108276461053,
                  61.33944082839785
                ],
                [
                  73.41083837494136,
                  61.34013142157465
                ],
                [
                  73.40724421486144,
                  61.340079885297875
                ]
              ]
            ]
          },
          properties: {
            description: "3",
            fill: "#ff931e", 
          }
        },
        {
          type: "Feature",
          id: 3,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.40770555481237,
                  61.330188499179314
                ],
                [
                  73.4094650839259,
                  61.33004415136257
                ],
                [
                  73.4096796606471,
                  61.33575568872475
                ],
                [
                  73.39652610763808,
                  61.33574538003156
                ],
                [
                  73.39380098327894,
                  61.33566291036365
                ],
                [
                  73.39270664200085,
                  61.33512685221292
                ],
                [
                  73.3927710150172,
                  61.33484851069734
                ],
                [
                  73.39285684570568,
                  61.333704191733354
                ],
                [
                  73.39225603088637,
                  61.332456733199884
                ],
                [
                  73.39369369491835,
                  61.33221960734656
                ],
                [
                  73.39609695419587,
                  61.332111353676936
                ],
                [
                  73.39638294473203,
                  61.32972951609465
                ],
                [
                  73.39263489290984,
                  61.32976028701861
                ],
                [
                  73.3923056517533,
                  61.32988369261682
                ],
                [
                  73.38898641809737,
                  61.32975932038926
                ],
                [
                  73.39054478153508,
                  61.32847948213974
                ],
                [
                  73.3927173708371,
                  61.3283531710019
                ],
                [
                  73.393049964755,
                  61.32728595027231
                ],
                [
                  73.39528156265534,
                  61.32649711153235
                ],
                [
                  73.40143991455362,
                  61.325868088573955
                ],
                [
                  73.40163303360262,
                  61.3265280467609
                ],
                [
                  73.40560270294469,
                  61.32656929368464
                ],
                [
                  73.40712619766516,
                  61.32828099299886
                ],
                [
                  73.40770555481237,
                  61.330188499179314
                ]
              ]
            ]
          },
          properties: {
            description: "4",
            fill: "#ff931e", 
          }
        },
        {
          type: "Feature",
          id: 4,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.40917665638538,
                  61.323603397025565
                ],
                [
                  73.41220093712164,
                  61.32215040747642
                ],
                [
                  73.41465784057927,
                  61.32319459978225
                ],
                [
                  73.41747952446298,
                  61.32390875567364
                ],
                [
                  73.42571927055671,
                  61.32542467145303
                ],
                [
                  73.4282512758668,
                  61.32551748023295
                ],
                [
                  73.4363193605836,
                  61.325321550263055
                ],
                [
                  73.44820691093757,
                  61.32783761011082
                ],
                [
                  73.45190031275106,
                  61.3324721980255
                ],
                [
                  73.44683898433992,
                  61.33355986039259
                ],
                [
                  73.44710184082336,
                  61.33503407213186
                ],
                [
                  73.43097640022584,
                  61.33460109407793
                ],
                [
                  73.42284394249263,
                  61.33154945947513
                ],
                [
                  73.42069817528075,
                  61.329198672756334
                ],
                [
                  73.41668559059445,
                  61.32850783775569
                ],
                [
                  73.41235114082633,
                  61.324981247875975
                ],
                [
                  73.40917665638538,
                  61.323603397025565
                ]
              ]
            ]
          },
          properties: {
            description: "5",
            fill: "#ff931e", 
          }
        },
        {
          type: "Feature",
          id: 5,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.47594095214997,
                  61.27041602886515
                ],
                [
                  73.47923862161224,
                  61.267900013182455
                ],
                [
                  73.49636629204615,
                  61.26470963183469
                ],
                [
                  73.49687354780035,
                  61.26423083593032
                ],
                [
                  73.49990508048795,
                  61.26397078526085
                ],
                [
                  73.50171952678357,
                  61.26359001127955
                ],
                [
                  73.50217268390118,
                  61.26310266985552
                ],
                [
                  73.50470978121743,
                  61.26333078148626
                ],
                [
                  73.50622200227818,
                  61.26379509347197
                ],
                [
                  73.50935373276023,
                  61.26077172996089
                ],
                [
                  73.510166945006,
                  61.26068842513606
                ],
                [
                  73.5169002954619,
                  61.261607098196166
                ],
                [
                  73.51729927405317,
                  61.260128120498294
                ],
                [
                  73.52516485199114,
                  61.26020807212189
                ],
                [
                  73.5259373281875,
                  61.27235805671319
                ],
                [
                  73.52825475677636,
                  61.28283053464792
                ],
                [
                  73.52207494720604,
                  61.282582705831
                ],
                [
                  73.50911451324608,
                  61.282190306199276
                ],
                [
                  73.50362134918353,
                  61.28288216540445
                ],
                [
                  73.49795652374415,
                  61.283160970026486
                ],
                [
                  73.49589658722068,
                  61.28580432737597
                ],
                [
                  73.46958948120265,
                  61.28941793117545
                ],
                [
                  73.46976114257964,
                  61.287167221266884
                ],
                [
                  73.47023321136622,
                  61.28064131221263
                ],
                [
                  73.46504045471346,
                  61.273659561225685
                ],
                [
                  73.46941781982575,
                  61.27173827372212
                ],
                [
                  73.47594095214997,
                  61.27041602886515
                ]
              ]
            ]
          },
          properties: {
            description: "6",
            fill: "#ff931e", 
          }
        },
        {
          type: "Feature",
          id: 6,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.1750840299746,
                  61.25067902191885
                ],
                [
                  73.17714396649801,
                  61.25030689607988
                ],
                [
                  73.18006220990624,
                  61.251113163145604
                ],
                [
                  73.1837529295107,
                  61.249066444699714
                ],
                [
                  73.19233599835837,
                  61.249128468434925
                ],
                [
                  73.20057574445211,
                  61.250327569853525
                ],
                [
                  73.19821540051902,
                  61.252601601687424
                ],
                [
                  73.19928828412492,
                  61.2547307727835
                ],
                [
                  73.19289389783343,
                  61.257479876774745
                ],
                [
                  73.19049063855607,
                  61.257004485065586
                ],
                [
                  73.1892890089174,
                  61.25752121484327
                ],
                [
                  73.18508330518206,
                  61.25644640733207
                ],
                [
                  73.1750840299746,
                  61.25067902191885
                ]
              ]
            ]
          },
          properties: {
            description: "7",
            fill: "#ff931e", 
          }
        },
        {
          type: "Feature",
          id: 7,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.20294801437522,
                  61.26399953003276
                ],
                [
                  73.21004097216532,
                  61.27140771822054
                ],
                [
                  73.21888153307837,
                  61.27698538291895
                ],
                [
                  73.24222748034305,
                  61.28097177086047
                ],
                [
                  73.24411575548986,
                  61.300442005665985
                ],
                [
                  73.20381824724997,
                  61.29808887786102
                ],
                [
                  73.19944088213778,
                  61.29736639080512
                ],
                [
                  73.18596546404707,
                  61.29127619378233
                ],
                [
                  73.18407718890046,
                  61.29228786796135
                ],
                [
                  73.16528026812426,
                  61.28283569802249
                ],
                [
                  73.18793956988212,
                  61.2690524056485
                ],
                [
                  73.1917804931913,
                  61.2665109488363
                ],
                [
                  73.20294801437522,
                  61.26399953003276
                ]
              ]
            ]
          },
          properties: {
            description: "8",
            fill: "#ff931e", 
          }
        },
        {
          type: "Feature",
          id: 8,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.20969764941296,
                  61.267502959408695
                ],
                [
                  73.22458927386364,
                  61.26435177962473
                ],
                [
                  73.22167103045541,
                  61.26168594391152
                ],
                [
                  73.23664848559457,
                  61.252984234584865
                ],
                [
                  73.26729004138075,
                  61.2564776102081
                ],
                [
                  73.27445690386854,
                  61.25877186391633
                ],
                [
                  73.30428306811409,
                  61.2658189257483
                ],
                [
                  73.31883136981088,
                  61.27007532685046
                ],
                [
                  73.30226604693489,
                  61.27426917555383
                ],
                [
                  73.29797451251108,
                  61.27493022359832
                ],
                [
                  73.29226677172737,
                  61.28492687313585
                ],
                [
                  73.28368370287969,
                  61.28633110527425
                ],
                [
                  73.25020973437375,
                  61.28170516124015
                ],
                [
                  73.21969692462042,
                  61.27670672104973
                ],
                [
                  73.21408574336125,
                  61.27261132958858
                ],
                [
                  73.20969764941296,
                  61.267502959408695
                ]
              ]
            ]
          },
          properties: {
            description: "9",
            fill: "#ffd21e", 
          }
        },
        {
          type: "Feature",
          id: 9,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.35254666112827,
                  61.29507912043185
                ],
                [
                  73.31595060132904,
                  61.291326805542056
                ],
                [
                  73.29258319639125,
                  61.28487404254249
                ],
                [
                  73.29432126783291,
                  61.28162134556433
                ],
                [
                  73.32091805242463,
                  61.282628168857556
                ],
                [
                  73.33206531309057,
                  61.27504263626376
                ],
                [
                  73.3316361596482,
                  61.26781169058515
                ],
                [
                  73.34057328008578,
                  61.25986605494404
                ],
                [
                  73.32940313718238,
                  61.24796879078027
                ],
                [
                  73.33277073406137,
                  61.24327601585931
                ],
                [
                  73.35642915867733,
                  61.24415483635447
                ],
                [
                  73.3717846388257,
                  61.23766907799426
                ],
                [
                  73.39189090010645,
                  61.228054297100805
                ],
                [
                  73.40654109038739,
                  61.22860447598366
                ],
                [
                  73.43217301149944,
                  61.22658311907813
                ],
                [
                  73.47275636932623,
                  61.22854903838789
                ],
                [
                  73.501452426774,
                  61.23599871005368
                ],
                [
                  73.51546330839106,
                  61.24310400466865
                ],
                [
                  73.52952381219777,
                  61.24508446732336
                ],
                [
                  73.53274840247053,
                  61.252104028178486
                ],
                [
                  73.52513688615363,
                  61.260113849048075
                ],
                [
                  73.51720638651663,
                  61.26004612195232
                ],
                [
                  73.51678165259774,
                  61.26150700431522
                ],
                [
                  73.51022854352706,
                  61.26059838792024
                ],
                [
                  73.50931233292482,
                  61.260675190807866
                ],
                [
                  73.50615056093672,
                  61.263698281197904
                ],
                [
                  73.50482716700812,
                  61.2632666643833
                ],
                [
                  73.50214844886456,
                  61.263029757186914
                ],
                [
                  73.50164593900541,
                  61.26352520885083
                ],
                [
                  73.49980681633903,
                  61.26390914498359
                ],
                [
                  73.49677367430212,
                  61.26414776516659
                ],
                [
                  73.49623342736507,
                  61.26464388090906
                ],
                [
                  73.47911982175198,
                  61.2678418208206
                ],
                [
                  73.4758287621178,
                  61.27034593910239
                ],
                [
                  73.46933410450633,
                  61.27157328136038
                ],
                [
                  73.46467184722921,
                  61.27349070556661
                ],
                [
                  73.47005602434486,
                  61.28067128717685
                ],
                [
                  73.469364473265,
                  61.289413054480356
                ],
                [
                  73.44828275562539,
                  61.293997859659704
                ],
                [
                  73.42390414976045,
                  61.2973653859868
                ],
                [
                  73.38407871030728,
                  61.298335579082355
                ],
                [
                  73.35254666112827,
                  61.29507912043185
                ]
              ]
            ]
          },
          properties: {
            description: "0",
            fill: "#1bad03", 
          }
        },
        {
          type: "Feature",
          id: 10,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.42809034332382,
                  61.32169469640144
                ],
                [
                  73.4292919729625,
                  61.32058083655652
                ],
                [
                  73.43358350738632,
                  61.32136466799456
                ],
                [
                  73.43881917938339,
                  61.319508193249014
                ],
                [
                  73.43894610192484,
                  61.31613586329395
                ],
                [
                  73.43439707543556,
                  61.31266977459811
                ],
                [
                  73.43319544579687,
                  61.30544752176377
                ],
                [
                  73.44315180566012,
                  61.303177326225956
                ],
                [
                  73.45113405968844,
                  61.30965726625643
                ],
                [
                  73.44795832421484,
                  61.31469170637742
                ],
                [
                  73.46229204919042,
                  61.315269377148695
                ],
                [
                  73.46246371056736,
                  61.31382518019956
                ],
                [
                  73.4736217000693,
                  61.31407276155908
                ],
                [
                  73.47173342492283,
                  61.32459315653666
                ],
                [
                  73.43242296960051,
                  61.32537688730775
                ],
                [
                  73.42809034332382,
                  61.32169469640144
                ]
              ]
            ]
          },
          properties: {
            description: "10",
            fill: "#ff931e", 
          }
        },
        {
          type: "Feature",
          id: 11,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.3740599249272,
                  61.334542466923146
                ],
                [
                  73.37423158630419,
                  61.33239810216316
                ],
                [
                  73.37234331115768,
                  61.33194446767182
                ],
                [
                  73.37577653869675,
                  61.33078973195992
                ],
                [
                  73.37474657043504,
                  61.32782021550324
                ],
                [
                  73.39225603088423,
                  61.3244791720114
                ],
                [
                  73.39508844360395,
                  61.326428157466225
                ],
                [
                  73.39294267639212,
                  61.32723246522721
                ],
                [
                  73.39262081131034,
                  61.32831515450969
                ],
                [
                  73.3904965017705,
                  61.32842857692951
                ],
                [
                  73.38888717636156,
                  61.32975868184346
                ],
                [
                  73.39242769226118,
                  61.329913341511514
                ],
                [
                  73.39272809967086,
                  61.329799924483325
                ],
                [
                  73.396354446259,
                  61.32977930316122
                ],
                [
                  73.39603258117721,
                  61.332078496703154
                ],
                [
                  73.39360786422775,
                  61.332181595587336
                ],
                [
                  73.39210582717939,
                  61.3324290315207
                ],
                [
                  73.39277101501507,
                  61.3337589660659
                ],
                [
                  73.39259935363813,
                  61.33517131516743
                ],
                [
                  73.39386535629312,
                  61.33575891604043
                ],
                [
                  73.39332891449013,
                  61.33677945971321
                ],
                [
                  73.39068962081946,
                  61.336707301155435
                ],
                [
                  73.38965965255773,
                  61.3398821199401
                ],
                [
                  73.38150573715247,
                  61.33939767171118
                ],
                [
                  73.38206363662756,
                  61.33764534938167
                ],
                [
                  73.3792312239078,
                  61.33452184866042
                ],
                [
                  73.3740599249272,
                  61.334542466923146
                ]
              ]
            ]
          },
          properties: {
            description: "11",
            fill: "#ff931e", 
          }
        },
        {
          type: "Feature",
          id: 12,
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [
                  73.0223043058778,
                  61.34068608105554
                ],
                [
                  73.02625251754772,
                  61.336686684178076
                ],
                [
                  73.02350593551648,
                  61.334295047474015
                ],
                [
                  73.02968574508681,
                  61.333057922127075
                ],
                [
                  73.03466392501845,
                  61.335820767905695
                ],
                [
                  73.04118705734268,
                  61.33309916042824
                ],
                [
                  73.05440498336806,
                  61.3304185575814
                ],
                [
                  73.06599212631241,
                  61.331449585905226
                ],
                [
                  73.0744035337831,
                  61.33714024978488
                ],
                [
                  73.07938171371474,
                  61.34274742574362
                ],
                [
                  73.08727813705458,
                  61.34629262022202
                ],
                [
                  73.08264327987683,
                  61.34872455565954
                ],
                [
                  73.08092666610732,
                  61.34827115833937
                ],
                [
                  73.06388843509646,
                  61.35329274988414
                ],
                [
                  73.05230129215211,
                  61.35119088722019
                ],
                [
                  73.04843891117066,
                  61.34913010010498
                ],
                [
                  73.0474947735974,
                  61.34995443128153
                ],
                [
                  73.04388988468138,
                  61.35024294204918
                ],
                [
                  73.04277408573122,
                  61.34937740174424
                ],
                [
                  73.0391691968152,
                  61.34974835052873
                ],
                [
                  73.03728092166868,
                  61.34875914397163
                ],
                [
                  73.03813922855348,
                  61.348346965318676
                ],
                [
                  73.03307521793334,
                  61.344802004181595
                ],
                [
                  73.03487766239138,
                  61.34405998461538
                ],
                [
                  73.03298938724487,
                  61.34302937262167
                ],
                [
                  73.02715290042849,
                  61.342328537029616
                ],
                [
                  73.0223043058778,
                  61.34068608105554
                ]
              ]
            ]
          },
          properties: {
            description: "12",
            fill: "#ff931e", 
          }
        }
      ]
    },
    ymapsAPIerror: true,

    suggestValue: "",
  };

  constructor(props) {
    super(props);

    window.ymaps.ready(() => {
      console.log("ready");

      this.initMap();
      var suggestView = new window.ymaps.SuggestView("suggest", {
        provider: {
          suggest: (function(request, options) {
  
            return window.ymaps.suggest("Сургут , " + request)
            })
          },
      });

      suggestView.events.add("select", (e) => {
        //console.log(e.originalEvent.item.value, "select");
        this.setState({ suggestValue: addr })
        var addr = e.originalEvent.item.value;
        this.getMKADDistance(addr);
      });

      document.getElementById("suggest").onblur = () => {
        //console.log("document.getElementById ", document.getElementById("suggest").value );
        setTimeout(() => {
          if (
            this.state.suggestValue == "" ||
            document.getElementById("suggest").value != this.state.suggestValue) this.getMKADDistance(document.getElementById("suggest").value);
        }, 1000)

      }

    });
  }

  componentDidMount() {
    if (this.props.value && this.props.value != "") {
      document.getElementById("suggest").value = this.props.value;
    }
  }

  componentDidUpdate(nProps, nState) {
    if (nProps.value != this.props.value) {
      this.getMKADDistance(this.props.value);
    }
  }


  initMap() {
    this.map = new window.ymaps.Map("ymap", {
      center: [57.145138, 65.580331] , // куда ставить центр карты по умолчанию
      zoom: 13, // зум
    }); // Создание экземпляра карты и его привязка к созданному контейнеру
    this.state.ymapsAPIerror = false; // с API все в порядке
  }


  /*
   * Делаем некоторые замены в адресе, чтобы не было неточностей результата
   */
  refineAddress(address) {
    address = address.toUpperCase();

    address = address.replace("ПРОСПЕКТ", "");
    address = address.replace("ПРКТ", "");
    address = address.replace("ПР-КТ", "");
    address = address.replace("ПР-Т", "");
    address = address.replace("ПРС-КТ", "");
    address = address.replace("ПРС-Т", "");
    address = address.replace("П-КТ", "");
    address = address.replace("П-Т", "");

    return address;
  }

  getMKADDistance(_addr) {
    // Добавляем зоны на карту.

    var deliveryZones = window.ymaps.geoQuery(this.state.json).addToMap(this.map);
    // Задаём цвет и контент балунов полигонов.
    deliveryZones.each(  (obj) => { 
      obj.options.set({
        fillColor: obj.properties.get('fill'),
        fillOpacity: obj.properties.get('fill-opacity'),
        strokeColor: obj.properties.get('stroke'),
        strokeWidth: obj.properties.get('stroke-width'),
        strokeOpacity: obj.properties.get('stroke-opacity')
      });
      obj.properties.set('balloonContent', obj.properties.get('description'));

    }); 

    this.map.setBounds( deliveryZones.getBounds() );
    
 

    var geocoder = new window.ymaps.geocode(this.refineAddress(_addr)).then(
      (res) => {
        var firstGeoObject = res.geoObjects.get(0),
          // Координаты геообъекта.
          coords = firstGeoObject.geometry.getCoordinates();
        var polygon = deliveryZones.searchContaining(coords).get(0);

        //console.log(polygon, coords, firstGeoObject);
        //console.log(myPolygon.geometry.contains(coords) ? 'Попал!' : 'Мимо!');

        if (polygon) {
          console.log("popal")
          this.props.callBack(_addr, polygon.properties.get('description'));
        } else { 
          this.props.callBack(_addr, null);
        }
      }).catch((err) => {
        console.log(err)
      })


  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>

        <input
          id="suggest"
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          type={this.props.type}
          className={"text-input" + (this.props.btnText ? " text-right" : "") + (this.props.overflow ? " overflow" : "")}
        />
        {this.props.btnText ? (
          <button className="btn" onClick={this.props.onClick}>
            {this.props.btnText}
          </button>
        ) : null}
      </div>
    );
  }
}
export default UiTextInputSV;
